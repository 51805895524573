/**
 * This is some VERY bare-bones styling for the WYSIWYG editor.
 */

.wysiwyg {
  color: #111;
  font-family: Roboto, 'Open Sans', sans-serif !important;
}

.wysiwyg p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.wysiwyg h1,
.wysiwyg h2 {
  font-size: 1.6rem;
}

.wysiwyg h2,
.wysiwyg h3 {
  font-size: 1.4rem;
}

.wysiwyg h5,
.wysiwyg h6 {
  font-size: 1.2rem;
}

.wysiwyg a,
.wysiwyg a:hover,
.wysiwyg a:visited {
  color: blue !important;
  text-decoration: underline;
}
