.container {
  position: relative;
}

.popup {
  position: absolute;
  bottom: -214px;
  width: 124px;
  height: 215px;
  border-radius: 0 0 4px 4px;
  box-shadow: var(--boxShadowButtonBasic);
  border: 1px solid var(--borderDarkAlt);
  background-color: #ffff;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.timeOptions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  position: relative;
}

.timeOptions::-webkit-scrollbar {
  width: 4px;
}

.timeOption {
  flex-basis: 0;
  padding: 4px 12px;
  text-align: right;
  cursor: pointer;
}

.timeOption:hover {
  background: var(--grey3);
  color: #111;
}

.ampmOptions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 60px;
  border-left: 1px solid var(--borderDarkAlt);
}

.ampmOption {
  flex-basis: 0;
  padding: 4px 12px;
  cursor: pointer;
}

.ampmOption:hover {
  background: var(--grey3);
}

.ampmOption:focus,
.timeOption:focus,
.timeOptionSelected {
  background: var(--blue);
  color: #fff;
}
