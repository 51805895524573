@value f4 from './typography.css';

.details {
  padding-left: 10px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-secondary);
}

.detailsVertical {
  padding-left: 10px;
  padding-right: 10px;
}

.durationIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: url('/static/img/icon-shift-time.png') no-repeat;
  background-size: cover;
  font-size: 12px;
  font-weight: bold;
  color: var(--blue);
}

.shiftTypeIcon {
  width: 24px;
  height: 24px;
  border-radius:12px;
  border:6px solid var(--blue);
  font-size: 12px;
  font-weight: bold;
}

.role {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.location {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.location img {
  display: block;
  margin-top: 2px;
  margin-left: 0;
  margin-right: 5px;
  width: 9px;
  height: 14px;
}

.position {
  composes: f4;
  padding-right: 15px;

  color: var(--text-primary);
}

.shiftConflict {
  padding: 20px 30px;
  margin: 0 25px;
  border: solid 1px #eaedf3;
}
.shiftConflictTitle {
  font-size: 20px;
  color: #2670d3;
}
