.channelSummary {
  margin-bottom: 0;
  margin-top: 0;
  padding: 16px;
  border: none;
  border-bottom: solid 1px var(--grey5);
  background-color: var(--bgWhite);
  transition: all 0.2s ease;
}

.channelSummaryActive {
  margin-bottom: 0;
  margin-top: 0;
  padding: 16px;
  border: none;
  border-bottom: solid 1px var(--grey5);
  background-color: var(--blue);
  box-shadow: inset -3px 0 0 0 var(--text-meta-alt);
  transition: all 0.2s ease;
}

/* PostCSS auto adds :focus to :hover styles */
.channelSummary:focus {
  background-color: var(--bgWhite);
}
.channelSummary:hover {
  background-color: var(--bgLightest);
}

.channelAvatar {
  width: 45px;
  height: 45px;
  padding: 0px;
  position: relative;
  font-family: Roboto, inherit;
  font-size: 19.2px;
  font-weight: 500;
}

.channelDisplayName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Roboto, inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--text-primary);
  margin-top: -4px;
}

.channelPreviewMessage {
  font-family: Roboto, inherit;
  font-size: 14px;
  line-height: 1.57;
  color: var(--text-secondary);
}

.channelTimestamp {
  font-family: Roboto, inherit;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: var(--grey6);
  margin-top: -1px;
}

.channelSelected {
  color: var(--bgWhite) !important;
}

.channelShiftGroupType {
  font-size: 12px;
  color: var(--text-primary);
  font-weight: bold;
}

.channelShiftGroupDate {
  font-size: 12px;
  color: var(--text-meta);
  font-weight: bold;
}

.broadcastIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  padding: 4px;
  position: absolute;
  top: -6px;
  right: -8px;
  background: white;
  border-radius: 50%;
  border: 1px solid var(--blue);
  box-shadow: 0 2px 4px 0 var(--shadowPrimary);
}
