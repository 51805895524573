.svd-accordion-tab-header {
  background-color: #e0e0e0;
  min-height: 32px;
  line-height: 32px;
  margin-bottom: 1em;
  font-size: 14px;
  padding: 0 1em;
  cursor: pointer;

  .ext-icon-toolbox-arrow {
    float: right;
    margin-top: -2px;
  }
}

.svd-accordion-tab-content {
  overflow: hidden;
}