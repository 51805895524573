/* Override Semantic UI Styles */
.ssm-label.ui.circular.label {
  padding: 0.5em 0.25em !important;
}

.ssm-label.ui.circular.label.tiny {
  padding: 0.5em 0.125em !important;
}

.ssm-label.ui.blue.label {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
}

.ssm-label.ui.red.label {
  background-color: var(--red) !important;
  border-color: var(--red) !important;
}

.ssm-button-label.ui.circular.label {
  padding: 10px 20px !important;
  background-image: var(--gradientTertiary);
  transition: box-shadow 0.2s linear;
}

.ssm-button-label.ui.circular.label:hover,
.ssm-button-label.ui.circular.label.active {
  padding: 10px 20px !important;
  color: #ffffff !important;
  box-shadow: var(--boxShadowButtonPrimary);
  background-image: var(--gradientPrimary) !important;
}
