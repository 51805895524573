.userCount {
  border-radius: 50%;
  border: 2px solid var(--text-meta-alt);
  background: var(--bgWhite);
}

.userCountText {
  font-weight: 500;
  color: var(--text-meta-alt);
  letter-spacing: -0.8px;
}

.userAvatars {
}

.userAvatar {
  border-radius: 50%;
  overflow: hidden;
}

.userAvatarWithTooltip {
  margin-top: 0px;
  transition: margin-top 0.1s ease-out;
}

.userAvatarWithTooltip:hover {
  margin-top: -3px;
}
