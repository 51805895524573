
body {
  font-family: Roboto, 'Open Sans', sans-serif !important;
  color: #111;
  background-color: #ffffff;
}

a {
  /*color: #2a6cc4;*/
  color: #f0f0f0;
  text-decoration: none;
  transition: all 0.2s;
}

a:hover,
a:focus {
  border-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  text-decoration: none;
  color: #ffffff;
}

a,
button {
  font-family: Roboto, 'avenir next', avenir, 'helvetica neue', helvetica,
    ubuntu, noto, 'segoe ui', arial, sans-serif;
  outline: 0;
}

i.outline {
  outline: none;
}

h1,
h3,
h4 {
  color: #999999;
}

img {
  max-width: 100%;
}

input,
textarea {
  font-size: 1rem;
}

.ui > input,
textarea {
  font-size: inherit;
}

input::-ms-clear,
textarea::-ms-clear {
  display: none;
}

.sm-blue {
  color: #2a6cc4;
}

.bg-sm-blue {
  background-color: #2a6cc4;
}

/* Custom Tachyon-Style Classes */

@media screen and (min-width: 30em) {
  .w-35-ns {
    width: 35%;
  }
  .w-45-ns {
    width: 45%;
  }
}

.h6 {
  height: 32rem;
}

.h7 {
  height: 64rem;
}

.max-h1 {
  max-height: 1rem;
}

.max-h2 {
  max-height: 2rem;
}

.max-h3 {
  max-height: 4rem;
}

.max-h4 {
  max-height: 8.2rem;
}

.max-h5 {
  max-height: 16rem;
}

.max-h6 {
  max-height: 32rem;
}

.min-h1 {
  min-height: 1rem;
}

.min-h2 {
  min-height: 2rem;
}

.min-h3 {
  min-height: 4rem;
}

.min-h4 {
  min-height: 8rem;
}

.min-h5 {
  min-height: 16rem;
}

.min-h6 {
  min-height: 32rem;
}

.min-h-25 {
  min-height: 25%;
}

.min-vh-25 {
  min-height: 25vh;
}

.min-w1 {
  min-width: 1rem;
}

.min-w2 {
  min-width: 2rem;
}

.min-w3 {
  min-width: 4rem;
}

.min-w4 {
  min-width: 8rem;
}

.min-w5 {
  min-width: 16rem;
}

.min-w6 {
  min-width: 32rem;
}

.max-w1 {
  max-width: 1rem;
}

.max-w2 {
  max-width: 2rem;
}

.max-w3 {
  max-width: 4rem;
}

.max-w4 {
  max-width: 8.2rem;
}

.max-w5 {
  max-width: 16rem;
}

.max-w6 {
  max-width: 32rem;
}

.max-w7 {
  max-width: 64rem;
}

.max-w-container {
  max-width: 1127px;
}

.max-w-100 {
  max-width: 100%;
}

.h_fill {
  height: calc(100vh - 16rem);
}

.z-100 {
  z-index: 100;
}

.shadow-0 {
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.bg-sm-gray {
  background-color: #e6eaf0;
}

.white {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
}

/* Datepicker Container Width */
.react-datepicker-popper {
  z-index: 10 !important;
}

.wrapped-datepicker .react-datepicker__input-container {
  width: 100%;
}

.wrapped-datepicker .react-datepicker-wrapper {
  width: 100%;
}

.wrapped-datepicker .react-datepicker__time-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Dashboard */
.bottom-quarter {
  top: -25vh;
  margin-bottom: -25vh;
}

.ui.items > .item > .content > .header:not(.ui) {
  font-size: 1.1em;
}
.ui.items > .item > .content > .header .status {
  font-size: 0.925em;
  line-height: 16px;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.6);
  font-variant: small-caps;
}

.ui.divided.items > .item {
  padding: 0.5em 0;
}

.ui.divided.items > .item {
  border-top-width: 0;
}

.ui.divided.items > .item ~ .item {
  border-top-width: 1px;
}

div.react-datepicker__tether-element {
  filter: none !important;
}

.ui.message .header {
  font-family: Roboto, 'Open Sans', sans-serif !important;
}

body,
h1,
h2,
h3,
h4,
h5 {
  font-family: Roboto, 'Open Sans', sans-serif !important;
}

* i,
i.icon {
  font-family: Icons;
}

.ui.image img.h3,
.ui.image svg.h3 {
  height: 4rem;
}

.map-marker img.ui.image.w3 {
  max-width: none;
}

.ui.page.dimmer {
  z-index: 1005;
}

/* Prevent Avatar status circles from shining through Dropdowns */
.ui.active.visible.selection.dropdown {
  z-index: 200;
}

/** Add a "Link" style button class
 * See : https://github.com/Semantic-Org/Semantic-UI/issues/4805
 */
.ui.button.basic.link,
.ui.button.basic.link:hover,
.ui.button.basic.link:focus {
  box-shadow: 0 0 !important;
  /* padding: 0.25rem 0.5rem !important; */
  background: none !important;
}

/** Add a "Bare" style button class
 * See : https://github.com/Semantic-Org/Semantic-UI/issues/4805
 */
.ui.button.basic.bare {
  box-shadow: 0 0 !important;
}

.ui.button.basic.bare:hover,
.ui.button.basic.bare:focus {
  box-shadow: 0 0 grey !important;
}

.ui.button.fitted {
  padding: 0 0 0 0 !important;
  min-width: 1rem;
}

.ui.button.fitted-h {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 1rem;
}

.ui.button.fitted-v {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-width: 1rem;
}

.ui.label.compact {
  padding: 0.15rem 0.35rem 0.2rem;
  min-width: 1rem;
}

.ui.progress.mb0 {
  margin-bottom: 0 !important;
}

/* Prevent Rating Stars from highliting when clicked */
.ui.rating > i {
  outline: none !important;
}

/* Default avatar image */

.ui.image.center.br-100 .blue.user.circular.icon {
  margin-left: -0.24rem;
  margin-top: -0.13rem;
}

/* Avatar Cards */
.user-card div.shift-status.ui.floating.label,
i.pool-status {
  position: absolute;
  right: calc(50% - 4rem);
  top: 3.25rem;
  left: unset;

  border-color: #fff !important;
  border-width: 0.4rem;
}

.user-card .pool-status {
  z-index: 100; /* Taken from ui.label.floating */
  margin: 0 0 0 -1.5em !important; /* same */

  background-color: white;
  border-radius: 100%;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.1 !important;
}

/* .workers div.ui.segment {
  border: none;
  border-radius: 0;
  box-shadow: none;
} */

.workers div.ui.segment > .label {
  border-radius: 0;
  text-transform: uppercase;
  padding: 1em;
}

.ui.pa0 {
  padding: 0 0 0 0;
}

.ui.pa1 {
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.ui.pa2 {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.ui.mv0 {
  margin-bottom: 0;
  margin-top: 0;
}

.ui.ph0 {
  padding-left: 0;
  padding-right: 0;
}

.ui.pr0 {
  padding-right: 0;
}

.ui.pl0 {
  padding-left: 0;
}

.ui.pv0 {
  padding-top: 0;
  padding-bottom: 0;
}

.ui.pt0 {
  padding-top: 0;
}

.ui.pb0 {
  padding-top: 0;
}

/* react-input-range */
.input-range__track {
  background: #ccc;
}
.input-range__track.input-range__track--active {
  background: #2a6cc4;
}

.input-range__label.input-range__label--max .input-range__label-container {
  left: 25%;
}

.input-range__label.input-range__label--min .input-range__label-container {
  left: -25%;
}

.input-range__track > span.input-range__slider-container > .input-range__label {
  transition: opacity 1s ease-in-out;
}

.hasMinRange
  .input-range__track
  > span.input-range__slider-container:first-of-type
  > .input-range__label,
.hasMaxRange
  .input-range__track
  > span.input-range__slider-container:last-of-type
  > .input-range__label {
  opacity: 1;
}

.noMinRange
  .input-range__track
  > span.input-range__slider-container:first-of-type
  > .input-range__label {
  opacity: 0;
}

.noMaxRange
  .input-range__track
  > span.input-range__slider-container:last-of-type
  > .input-range__label {
  opacity: 0;
}

.auth-form .ui.buttons div.or::before {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.13);
}

/* Survey JS CSS overrides */
.panel-body a {
  color: #2a6cc4;
  font-weight: bold;
}

ul.nav.nav-tabs li a span {
  color: #2a6cc4;
  font-weight: normal;
}

.svd_menu li a {
  font-weight: normal;
}

/* Intake Buttons */
.ui.button.hover-dark-blue.active {
  color: #00449e;
}

.xs .ui.steps {
  border-radius: 0 !important;
}

.ui.steps .step.no-content > i.icon {
  margin-right: 0;
}

.intake .ui.button.basic.bg-white {
  background: white !important;
}

.intake .ui.steps .step.no-content.active > i.icon {
  color: #00449e;
}

.intake-status.xs .overflow-hidden .scroll-content {
  padding-right: 17px;
}

.intake-status.xs .overflow-hidden .scroll-content::-webkit-scrollbar {
  display: none;
}

.csb .ui.segments > .ui.segment.pointer:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

/* Moz Scrollbars per https://drafts.csswg.org/css-scrollbars-1/ */
/* https://stackoverflow.com/a/54101063/609021 */
.overflow-auto,
.overflow-y-auto,
.overflow-y-scroll {
  scrollbar-width: thin;
  scrollbar-color: #aaa transparent;
}

.overflow-auto::-webkit-scrollbar,
.overflow-y-scroll::-webkit-scrollbar,
.overflow-y-auto::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  margin-left: 4px;
}

.overflow-auto::-webkit-scrollbar-thumb,
.overflow-y-scroll::-webkit-scrollbar-thumb,
.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: transparent;
  opacity: 0;
}

.overflow-auto:hover::-webkit-scrollbar-thumb,
.overflow-y-scroll:hover::-webkit-scrollbar-thumb,
.overflow-y-auto:hover::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 2px;
  opacity: 1;
}

.overflow-auto::-webkit-scrollbar-track,
.overflow-y-scroll::-webkit-scrollbar-track,
.overflow-y-auto::-webkit-scrollbar-track {
  background-color: transparent;
  opacity: 0;
}

.panelGroup .divider {
  background-color: #f4f4f4 !important;
}

.hover-o-100:hover {
  opacity: 1 !important;
}

.ssm-stepper.ui.steps {
  border-radius: 0;
}

.ssm-stepper.ui.steps > .step {
  background: #f8f8f8;
  font-variant: small-caps;
  text-transform: uppercase;
}

.ssm-stepper.ui.steps > .step.active {
  background: #2a6cc4;
  border-radius: 0;
}

.ssm-stepper.ui.steps > .step.active .content .title {
  color: white;
}

.ssm-stepper.ui.steps > .step::after {
  display: none;
}

.ssm-content .ssm-content-group {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ssm-content .ssm-content-header {
  font-variant: small-caps;
  text-transform: lowercase;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.ssm-content .ssm-content-header.ssm-content-upper {
  text-transform: uppercase;
}

.ssm-content .ssm-content-header.small {
  padding-bottom: 0.25rem;
}

.ssm-content .ssm-content-subheader {
  width: 10rem;
  font-variant: small-caps;
  text-transform: lowercase;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}

.ssm-content .ssm-content-subheader + div {
  padding-top: 0.5rem;
}

.ssm-content .ssm-column-header {
  width: 10rem;
  font-variant: small-caps;
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}

.ssm-content .ui.segments.active,
.ssm-content .ui.segment.active {
  border-left: 3px solid #2a6cc4;
}

.ui.dropdown.ssm-inline-ddl {
  min-width: 6.5rem;
  min-height: 2rem;
  padding: 5px 20px 5px 5px;
}

.ui.dropdown.ssm-inline-ddl > i {
  right: 1px;
  padding-right: 1px;
  padding-left: 0;
  top: 0px;
}

.ui.fitted {
  padding: 0 0 0 0;
}

.ui.fitted div .ui.input,
.ui.fitted > .ui.input,
.ui.fitted .ui.search {
  border: none;
}

.ui.fitted .ui.input input {
  padding: 1rem;
  border: none;
}

.ui.fitted.input > input {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.ui.fitted-v.input > input {
  padding-top: 0;
  padding-bottom: 0;
}

.ui.fitted-h.input > input {
  padding-right: 0;
  padding-left: 0;
}

.ui.button .ui.input > input {
  border: 0px solid transparent;
  margin: 1px -2px 1px 1px;
  width: calc(100% - 2px);
  background: transparent;
}

.ui.button.primary:not(.basic) .ui.input > input {
  color: white;
}

.ui.button.primary.basic .ui.input > input {
  color: rgb(33, 133, 208);
}

.flex-grow-1,
.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink-1,
.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.Toastify__toast {
  padding: 14px;
  border-radius: 5px;
  box-shadow: var(--boxShadowHeavy);
}

.Toastify__close-button {
  width: 0;
  height: 0;
}

.Toastify__toast--info {
  background: var(--blue);
}

.Toastify__toast--warning {
  background: var(--orange);
}

div[role='button'] {
  outline: none;
}

div.ssm_container {
  max-width: 90rem;
}

.ssm-breadcrumb-main {
  color: #397cdd !important;
}

.ssm-no-content {
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: #555555;
  font-style: italic;
  width: 100%;
}

.ssm-sidebar-menu .ui.menu.inverted > .item,
.ssm-sidebar-menu .ui.menu.inverted {
  background-color: transparent !important;
  border: 0 0 0 0 !important;
}

#site-header-actions.menu > .item {
  padding-left: 0.5rem;
  padding-right: 0;
  margin-right: 0;
  margin-left: 0;
}

.ui.dropdown > i.dropdown.icon {
  padding-left: 0.25rem;
}

@keyframes showing {
  0% {
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  40% {
    transform: scale(0.2);
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.ui.popup.visible.transform-scale {
  animation: showing 300ms ease-in-out;
}

.ui.popup.l-1 {
  margin-left: -1rem !important;
  margin-right: 1rem !important;
}

body ::-webkit-scrollbar-track {
  background: transparent;
}

/* override react-pivot styles */
.reactPivot {
  margin-top: 0 !important;
}

.hoverable .showOnHover {
  opacity: 0;

  transition: opacity 0.3s;
  transition-delay: 0s;
}

.hoverable:hover .showOnHover {
  opacity: 1;
  transition-delay: 150ms;
}

.hoverable:hover .showOnHover.noDelay {
  transition-delay: 0ms;
}

.hoverable:hover .showOnHover.delay {
  transition-delay: 500ms;
}

/* disable blue border appears around children of hotkeys */
div[tabindex='-1']:focus {
  outline: 0;
}

.ssm-no-text-select {
  user-select: none;
}
