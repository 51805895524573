svd-splitter {
  display: table-cell;
  min-width: 4px;
  width: 4px;
  max-width: 4px;
  cursor: ew-resize;
  position: relative;

  div.svd-splitter {
    background-color: lightgray;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover,
  &.svd-active-splitter {
    & > div.svd-splitter.svd-splitter.svd-splitter {
      background-color: gray;
      & > div.ext-icon-split {
        & > svg.svd-svg-icon {
          fill: gray;
        }
      }
    }
  }

  & > div.svd-splitter.svd-splitter.svd-splitter > div.ext-icon-split {
    position: absolute;
    top: 50%;
    left: -6px;
    & > svg.svd-svg-icon {
      width: 16px;
      height: 16px;
      fill: lightgray;
    }
  }
}
