.certList .pill {
  border-color: #3a506b !important;
  cursor: pointer;

  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0;
}

.certList .pill.basic {
  background-color: #3a506b !important;
  cursor: pointer;

  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0;
}

.certList .basic {
  cursor: pointer;

  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0;
}

.certList .addNewPill {
  margin: 0.25rem;
  /*border-color: #999999 !important;*/
  cursor: pointer;
}

/* .certList .ui.labeled.button > .ui.compact.button {
  .5833em .833em; 
 padding: .58928571em 1.125em;
} */

.certList .ui.labeled.button > .ui.label {
  padding-top: 0;
  padding-bottom: 0;
}

.certList .ui.button > .ui.label > .icon {
  margin-right: 0;
}

.certList .micro {
  font-weight: 300;
  padding: 0.3rem 0.5rem;
}

/*.certList .addNewPill i {
  color: #999999;
}*/
