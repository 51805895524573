/**
 * Overrides and customizations for Semantic UI Components
 * 
 * To date, most of these customizations have been added to _.global.css,
 * however, in an effort to clean and organize our CSS, new overrides to
 * any Semantic UI components should be made here.
 */

/* Override size of <Statistic.Label> component 
 * Class name to be added on the `Statistic.Label` component,
 * in addition to the top level `Statistic` component.
 */

.ui.statistic.mini .label.small {
  font-size: 83%;
}

.ui.statistic.mini .label.tiny {
  font-size: 70%;
}

.ui.statistic.mini .label.mini {
  font-size: 50%;
}

/* New Modal Styles */
.modal.ssm-modal > .header {
  padding: 18px 25px;
}
.modal.ssm-modal > .header {
  border-bottom: 1px solid var(--borderDark);
}
.modal.ssm-modal > .header .ui.header {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-primary);
  margin-top: 0px;
  margin-bottom: 0px;
}

.modal.ssm-modal > .content {
  padding: 0 0 0 0;
}
.modal.ssm-modal > .content .ssm-modal-content {
  padding: 0 2.5rem 0 2.5rem;
}

.modal.ssm-modal > .actions {
  /* padding: 0.5rem 2.5rem 2.5rem 2.5rem; */
  padding: 10px 25px 30px 25px;
}
.modal.ssm-modal > .actions {
  border-top: none;
  background-color: #ffffff;
}

.ui.popup.ssm-popup-actions {
  padding: 0;
}

.ui.popup.ssm-popup-actions .ui.buttons > .ui.button {
  text-align: left;
  background: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 4;
}
.ui.popup.ssm-popup-actions .ui.buttons > .ui.button:hover,
.ui.popup.ssm-popup-actions .ui.buttons > .ui.button.active {
  background: var(--borderMid);
}

.ui.buttons.ttu > .ui.button,
.ui.button.ttu {
  text-transform: uppercase;
}

/* Progress bar - user brand colors */
/* Can overwrite specific colors on each page/component */
/* by overriding the css bar in the top level class */
.ui.red.progress .bar {
  background-color: var(--red);
}
.ui.blue.progress .bar {
  background-color: var(--blue);
}
.ui.green.progress .bar {
  background-color: var(--green);
}
.ui.yellow.progress .bar {
  background-color: var(--yellow);
}
.ui.orange.progress .bar {
  background-color: var(--orange);
}

.ui.progress .bar {
  min-width: 0.5em;
}

/* Style DDLs near Sticky Headers */
.ui.active.visible.selection.dropdown.z_over_sticky,
.ui.active.dropdown.z_over_sticky {
  z-index: 900;
}

/* Style Disabled DDL Items */
.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
  cursor: not-allowed;
}

.ui.dropdown .menu > .disabled.item:hover {
  background: unset;
}

/** sticky header test */
.ui.table.ssm_sticky_header {
  border-top: none;
}
.ui.table.ssm_sticky_header thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
.ui.table.ssm_sticky_header tbody {
  overflow-y: scroll;
}
/** #end sticky header test */

.ui.segment > .ui.table {
  border-top: none;
}

.ui.sortable.table.ssm_sticky_header thead th:hover,
.ui.sortable.table.ssm_sticky_header thead th.sorted {
  background: #e9edf1;
  color: rgba(0, 0, 0, 0.8);
}

/* Checkbox Toggle */
.ssm-toggle-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
}
.ssm-toggle.ui.toggle.checkbox {
  margin: 0 !important;
  height: 31px;
}
.ssm-toggle.ui.toggle.checkbox input:checked ~ .box:before,
.ssm-toggle.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #83d133 !important;
  font-family: shiftsmart-icons !important;
  text-transform: lowercase;
  content: '\2713';
  color: #ffffff;
  padding: 8px 12px;
}

.ssm-toggle.ui.toggle.checkbox input:checked ~ .box:before,
.ssm-toggle.ui.toggle.checkbox input:checked ~ label:before,
.ssm-toggle.ui.toggle.checkbox input ~ .box:before,
.ssm-toggle.ui.toggle.checkbox input ~ label:before {
  width: 65px;
  height: 31px;
}

/* Knob - checked */
.ssm-toggle.ui.toggle.checkbox input:checked ~ .box:after,
.ssm-toggle.ui.toggle.checkbox input:checked ~ label:after {
  left: 36px;
  top: 1px;
}
.ssm-toggle.ui.toggle.checkbox input ~ .box:after,
.ssm-toggle.ui.toggle.checkbox input ~ label:after {
  left: 1px;
  top: 1px;
}

/* Knob */
.ssm-toggle.ui.toggle.checkbox input:checked ~ .box:after,
.ssm-toggle.ui.toggle.checkbox input:checked ~ label:after,
.ssm-toggle.ui.toggle.checkbox input ~ .box:after,
.ssm-toggle.ui.toggle.checkbox input ~ label:after {
  width: 28px;
  height: 28px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 3px 1px 0 rgba(0, 0, 0, 0.05);
  border: solid 0.5px rgba(0, 0, 0, 0.1);
}

.ssm-toggle-label {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.ssm-toggle-description {
  font-weight: normal;
  margin-top: 5px;
}

/* Form */
.ssm-form {
  padding: 20px 25px 10px;
}

.ssm-form.ui.form .field > label {
  font-size: 1em !important;
  color: var(--text-primary);
}

/* override blue border on inputs */
input:not([type]):focus,
input[type='date']:focus,
input[type='datetime-local']:focus,
input[type='email']:focus,
input[type='file']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='time']:focus,
input[type='url']:focus,
textarea:focus,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown .menu {
  border-color: var(--blue) !important;
}

.caret {
  border-top: none !important;
}

.ssm-label {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.ssm-value {
  font-weight: normal;
  margin-top: 5px;
  margin-bottom: 15px;
}

/* Override Semantic Icon Colors */
i.green.icon {
  color: var(--green) !important;
}
i.blue.icon {
  color: var(--blue) !important;
}
i.orange.icon {
  color: var(--orange) !important;
}
i.red.icon {
  color: var(--red) !important;
}

/* Override Dropdown Filters */
.ssm-filter.ui.selection.dropdown:not(.active) {
  background: var(--blue-navy);
  border-color: var(--blue-navy);
  color: #ffffff !important;
}

.ssm-filter.ui.selection.dropdown:not(.active) > .divider.text{
  color: #ffffff !important;
}


.ssm-filter.ui.selection.visible.dropdown > .text:not(.default) {
  color: unset;
}

.ssm-search.fluid .ui.input {
  width: 100%;
}

.ssm-search .ui.input > input {
  background: none !important;
  border-radius: 4px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ssm-search:hover .ui.input > input {
  background: #ffffff !important;
}

.ssm-search {
  margin-left: 20px;
}

.ssm-search .results {
  max-height: 200px;
  overflow-y: scroll;
}

.ssm-search.ui.search > .results .result .image {
  float: right;
  overflow: hidden;
  width: inherit !important;
  height: inherit !important;
  border-radius: inherit !important;
}

/** Set a Read-Only Form Field style */
.ui.input > input[readonly],
.ui.input > input[readonly][type='text']:focus {
  border-color: rgba(34, 36, 38, 0.04) !important;
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
}
