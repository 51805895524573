.svd_container.sv_bootstrapmaterial_css {
  .panel.card {
    box-shadow: none;
  }
  .form-group {
    margin-top: 0;

    .form-control {
      background-image: linear-gradient(
          0deg,
          var(--primary-color, $primary-color) 2px,
          rgba(213, 0, 0, 0) 0
        ),
        linear-gradient(0deg, rgba(0, 0, 0, 0.26) 1px, transparent 0);
    }
  }
  .form-group {
    margin-top: 0;

    &.is-focused {
      label {
        color: var(--primary-color, $primary-color);
      }
    }
  }

  .svd_custom_select .form-group,
  .svd_properties_grid .form-group {
    margin-top: 0;
    padding-bottom: 0;
    .form-control {
      margin-top: -3px;
      margin-bottom: 0;
    }
  }
  .svd_properties_grid {
    .glyphext-icon-edit {
      border-radius: 0;
      background-color: var(--primary-color, $primary-color);
      color: white;
      text-transform: uppercase;
      padding: 5px;
      font-size: 12px;

      &:hover {
        background-color: rgba(153, 153, 153, 0.2);
      }
    }
  }

  .svda-adorner-root span {
    position: static;
    display: inline-block;
    transition: none;
  }
  .modal-footer .btn + .btn {
    margin-bottom: 10px;
  }

  .svd-propertyeditor-condition {
    .btn-primary {
      margin-right: 0.5em;
    }

    .svd_custom_select {
      max-width: 165px;
    }

    textarea {
      color: var(--primary-text-color, $primary-text-color);
    }
  }

  .svd-logic-button {
    margin-right: 0.5em;
  }

  select.svda_current_type {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
  }

  .svd_question {
    border: 1px solid var(--primary-border-color, $primary-border-color);
  }

  .modal-content {
    .form-group {
      .sjs-cb-wrapper {
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .btn-danger {
      background-color: #f44336;
      color: var(--secondary-bg-color, $secondary-bg-color);
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .modal-content {
    @media (min-width: 768px) {
      .form-inline .checkbox input[type="checkbox"],
      .form-inline .radio input[type="radio"] {
        position: absolute;
        margin-left: 0;
      }

      .form-inline .checkbox span,
      .form-inline .radio span {
        top: 4px;
      }

      .form-inline .checkbox label,
      .form-inline .radio label {
        padding-left: 45px;
        margin-bottom: 10px;
      }
    }
  }

  .sv_select_wrapper .form-control {
    line-height: 3em;
    height: 3em;
  }

  survey-widget
    .sv_bootstrapmaterial_css
    .svd-property-editor-survey
    .svd-property-editor-survey-page
    > .sv_row
    .sv_qstn
    select {
    line-height: 3em;
    height: 3em;
  }

  .btn-primary {
    color: var(--inverted-text-color, $inverted-text-color);
    background-color: var(--secondary-color, $secondary-color);
    border-color: var(--secondary-color, $secondary-color);

    &:active:focus {
      color: var(--primary-color, $primary-color);
    }
  }

  .btn-secondary {
    background-color: var(--primary-border-color, $primary-border-color);
    &:hover {
      opacity: 0.8;
    }
  }

  .btn-link {
    color: var(--primary-text-color, $primary-text-color);
    background-color: var(--secondary-bg-color, $secondary-bg-color);
    border-color: var(--secondary-bg-color, $secondary-bg-color);
  }

  .checkbox label,
  .radio label,
  label {
    color: var(--primary-text-color, $primary-text-color);
  }

  .editor-tabs {
    > li {
      a:hover,
      a:focus {
        background-color: var(
          --secondary-bg-color,
          $secondary-bg-color
        ) !important;
        border-bottom: 1px solid var(--secondary-bg-color, $secondary-bg-color) !important;
      }

      &.active a {
        color: var(--primary-color, $primary-color) !important;
        background-color: var(
          --secondary-bg-color,
          $secondary-bg-color
        ) !important;
        border: 1px solid var(--primary-border-color, $primary-border-color) !important;
        border-bottom-color: var(
          --secondary-bg-color,
          $secondary-bg-color
        ) !important;
      }
    }
  }

  .nav-tabs {
    a {
      color: var(--primary-text-color, $primary-text-color) !important;
    }

    > li.active > a {
      color: var(--primary-color, $primary-color) !important;
    }
  }

  .nav-item.active .nav-link {
    background-color: var(--primary-bg-color, $primary-bg-color) !important;
  }

  .modal-tabs {
    background: transparent;
    padding-bottom: 10px;

    > li > a {
      color: var(--primary-text-color, $primary-text-color) !important;
      border: 1px solid transparent !important;

      &:hover {
        color: var(--primary-color, $primary-color) !important;
        border-bottom: 1px solid
          var(--inverted-text-color, $inverted-text-color) !important;
      }

      &:focus {
        color: var(--primary-color, $primary-color) !important;
        border-bottom: 1px solid
          var(--inverted-text-color, $inverted-text-color) !important;
      }
    }

    > li.active > a {
      color: var(--primary-color, $primary-color) !important;
      border: 1px solid var(--primary-border-color, $primary-border-color) !important;
      border-bottom-color: var(
        --inverted-text-color,
        $inverted-text-color
      ) !important;
    }
  }
}
