@import "../colors";

svd-designer-container {
  vertical-align: top;
}

.svd-designer-container {
  overflow: hidden;
  height: 100%;
}

.svd-designer-container--left-side {
  display: table-cell;
  vertical-align: top;
  width: 15%;
  max-width: 15%;
  flex-basis: 15%;
  .svd-designer-container__header {
    text-align: left;
  }
  .svd-ext-icon-container--left-open,
  .svd-ext-icon-container--left-close {
    display: block;
    float: right;
  }
}

.svd-designer-container--right-side {
  display: table-cell;
  vertical-align: top;
  width: 35%;
  max-width: 35%;
  flex-basis: 35%;
  .svd-designer-container__header {
    text-align: right;
  }
  .svd-ext-icon-container--right-open,
  .svd-ext-icon-container--right-close {
    display: block;
    float: left;
  }

  // TODO: remove
  .svd_toolbox {
    margin-left: 1em;
  }
}

.svd-designer-tabbed-container__tab-header-container {
  padding: 0 1em;
}

.svd-designer-tabbed-container__tab-header {
  display: inline-block;
  cursor: pointer;
  font-size: 1.17em;
  text-transform: uppercase;
  padding: 5px 10px 10px 10px;
  border-bottom: 4px solid transpereant;
  &:hover {
    color: $primary-color;
    color: var(--primary-color, $primary-color);
  }
}

.svd-designer-tabbed-container__tab-header-active {
  border-bottom: 4px solid $primary-color;
  border-bottom-color: var(--primary-color, $primary-color);
}

.svd-designer-tabbed-container__tab-content {
  display: none;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &.svd-designer-tabbed-container__tab-content-active {
    display: block;
  }
}

.svd-designer-container__header {
  padding: 0 1em;
  height: 1.5em;
  margin-bottom: 3em;
  margin-top: 0.35em;
  user-select: none;
}

.svd-designer-container__header-title {
  font-size: 1.17em;
  font-weight: 600;
  text-transform: uppercase;
}

.svd-ext-icon-container {
  display: none;
  cursor: pointer;
}
.svd-ext-icon-container.svd-ext-icon-container.svd-ext-icon-container {
  & > svg.svd-svg-icon, & > svg-icon > svg.svd-svg-icon {
    width: 14px;
    height: 24px;
    fill: lightgray;
    &:hover {
      fill: gray;
    }
  }
}
