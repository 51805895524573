@value link from './buttons.css';

/* CSS vars defined on container */

.vars {
  --fastTransition: box-shadow 0.2s, transform 0.2s;
  --slowTransition: box-shadow 0.4s, transform 0.4s;
}

.container {
  --subheader-height: 56px;
  --red: #f05252;
  overflow-y: scroll;
  height: 100%;
  background: #f1f1f1;
}

.noScrollContainer {
  --subheader-height: 56px;
  --red: #f05252;
  overflow: hidden;
  height: 100%;
  background: #f1f1f1;
}

.shiftHeaderStats {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
}

.shiftHeaderStats:hover .shiftHeaderStatsLabel {
  color: #000000;
}
.shiftHeaderStats:hover .shiftHeaderStatsIcon {
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.11);
}

.shiftHeaderStats:last-child {
  margin-right: 0;
}

.shiftHeaderStatsIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
  background: var(--bgWhite);
  position: relative;
}

.shiftHeaderStatsChart {
  display: block;
  width: 44px;
  height: 44px;
  stroke: var(--blue-alt-4);
}

.shiftHeaderStatsChartBg {
  fill: none;
  stroke: #eee;
  stroke-width: 4;
}

.shiftHeaderStatsCircle {
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  animation: progress 0.6s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.shiftHeaderStatsPercent {
  fill: var(--blue-alt-4);
  font-size: 10px;
  text-anchor: middle;
}

.shiftHeaderStatsValue {
  position: absolute;
  top: -10px;
  right: -10px;
}

.shiftHeaderStatsLabel {
  display: block;
  padding-left: 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--text-secondary);
  white-space: pre-line;
  transition: all 0.2s linear;
}

.shiftHeaderTabs {
  height: 41px;
}

.shiftHeaderTab {
  height: 28px;
  transition: all 0.2s linear;
}

.shiftHeaderTab:hover {
  background: rgba(100%, 100%, 100%, 0.4);
}

.shiftHeaderTabSelected,
.shiftHeaderTab:focus {
  background: #fff;
  background: var(--bgWhite);
}

.shiftHeaderTabText {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.shiftActionsList {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.shiftAction {
  padding: 0.5em 1em;
}

.shiftAction:hover {
  background: #f1f1f1;
}

.shiftAction.disabled {
  color: var(--grey4);
  cursor: default;
  pointer-events: none;
}

.shiftSearch {
  margin-top: 20px;
  border-top: 1px solid var(--grey4);
  padding-top: 30px;
}

.shiftDateCalendar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  user-select: none;
}

.shiftDateCalendarPopup {
  position: absolute;
  top: 80px;
  left: -210px;

  border-radius: 0.28571429rem;
  
  color: rgba(0,0,0,.87);
  padding: 0.833em 1em;
  background: var(--bgWhite);
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.shiftDateCalendarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.shiftDateCalendarHeaderTitle {
  display: block;
  width: 145px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: var(--grey8);
  text-transform: uppercase;
}

.shiftDateCalendarHeaderButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 25px;
  border-radius: 12px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
  background: var(--bgWhite);
  transition: all 0.2s linear;
}
.shiftDateCalendarHeaderButton:hover {
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.11);
}

.shiftDateCalendarHeaderButtonText {
  font-size: 11px;
  font-weight: bold;
  color: var(--text-secondary);
}

.shiftDateCalendarWeek {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
  border-top: 1px solid var(--borderMid);
  height: 48px;
  margin-left: 20px;
}

.shiftDateCalendarWeekDay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 48px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  color: var(--grey8);
}

.shiftDateCalendarDays {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--borderMid);
  width: 340px;
}

.shiftDateCalendarDay {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--borderMid);
  width: 46px;
  height: 48px;
}

.shiftDateCalendarDayText {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 13px;
  font-weight: normal;
  color: #121212;
  transition: all 0.2s linear;
}

.shiftDateCalendarDayText:hover {
  background: var(--grey4);
}

.shiftDateCalendarDaySelected {
  color: #fff;
  background: var(--blue-navy);
}

.shiftDateCalendarPrevMonth,
.shiftDateCalendarNextMonth {
  color: #c4c4c4;
}

.shiftFilterGroup {
  max-height: 300px;
  overflow-y: scroll;
}

.shiftFilterStatusContent {
  width: 200px;
}

.shiftFilterTitle,
.shiftSelectionTitle {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--text-primary);
}

.shiftFilterStatusIndicator {
  width: 8px;
  height: 17px;
  margin-top: -2px;
  border-radius: 45px;
  margin-right: 8px;
}

.shiftFilterStatusIndicatorActive {
  background-color: var(--blue);
}

.shiftFilterStatusIndicatorDraft,
.shiftFilterStatusIndicatorPending {
  background-color: var(--text-primary);
}

.shiftFilterStatusIndicatorFilled {
  background-color: var(--filled);
}

.shiftFilterStatusIndicatorCompleted {
  background-color: var(--steel);
}

.shiftFilterStatusIndicatorCanceled,
.shiftFilterStatusIndicatorDeleted,
.shiftFilterStatusIndicatorDisabled,
.shiftFilterStatusIndicatorExpired {
  background-color: var(--red);
}

.shiftFilterSelect {
  margin-top: 8px;
  width: 100%;
  font-size: 14px;
  font-weight: normal !important;
  color: var(--grey10) !important;
}

/** @see `ssm-ui/Checkbox:FilterCheckbox` for start of replacement */
.shiftFilterCheckbox {
  display: block !important;
  width: 100%;
  margin-right: 0.5rem;
}

.shiftFilterCheckbox label {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shiftFilterCheckbox input:checked ~ label:before {
  border-color: var(--blue-navy) !important;
  background: var(--blue-navy) !important;
}

.shiftFilterCheckboxActive input:checked ~ label:before {
  border-color: var(--blue) !important;
  background: var(--blue) !important;
}

.shiftFilterCheckboxDraft input:checked ~ label:before,
.shiftFilterCheckboxPending input:checked ~ label:before {
  border-color: var(--grey8) !important;
  background: var(--grey8) !important;
}

.shiftFilterCheckboxFilled input:checked ~ label:before {
  border-color: var(--filled) !important;
  background: var(--filled) !important;
}

.shiftFilterCheckboxCompleted input:checked ~ label:before {
  border-color: var(--steel) !important;
  background: var(--steel) !important;
}

.shiftFilterCheckboxCanceled input:checked ~ label:before,
.shiftFilterCheckboxDeleted input:checked ~ label:before,
.shiftFilterCheckboxDisabled input:checked ~ label:before,
.shiftFilterCheckboxExpired input:checked ~ label:before {
  border-color: var(--red) !important;
  background: var(--red) !important;
}

.shiftFilterCheckbox input:checked ~ label:after {
  color: #fff !important;
}

.shiftFilterCheckboxRow {
  width: 100%;
}

.shiftFilterCheckboxAll,
.shiftFilterCheckboxOnly {
  composes: link;
  margin-left: 1rem;
}

.shiftFilterCheckboxAll {
  margin-top: 2px;
  margin-bottom: 8px;
}

.shiftFilterCheckboxAll:hover,
.shiftFilterCheckboxOnly:hover {
  color: var(--blue-75);
}

.shiftFilterCheckboxAll,
.shiftFilterCheckboxRow > .shiftFilterCheckboxOnly {
  transform: scale(0);

  transition: transform ease-out 0.2s;
}

.shiftFilterCheckboxAll.visible,
.shiftFilterCheckboxRow:hover > .shiftFilterCheckboxOnly {
  display: block;
  transform: scale(1);
}

.shiftFilterCheckboxRow:hover
  > .shiftFilterCheckboxOnly.shiftFilterCheckboxSoleSelection {
  display: unset;
  transform: scale(0);
}

.right {
  width: 77%;
}

.shiftTimeLineCalendar {
  padding: 38px 38px 38px 25px;
  height: 100%;
}

.shiftTimeLineCalendarHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 0;
  padding: 10px 25px;
  border-radius: 3px;
  box-shadow: 0 2px 16px 0 rgba(127, 133, 139, 0.2);
  background: var(--bgWhite);
  position: sticky;
  top: 54px;
  z-index: 7;
}

.shiftTimeLineCalendarContentTabs {
  width: 300px;
  height: 100%;
  padding: 0 20px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.shiftTimeLineCalendarHeaderDate {
  width: 500px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shiftTimeLineCalendarHeaderTitle {
  width: 100%;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 20px 5px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  position: relative;
}

.shiftTimeLineCalendarDatesToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  flex-shrink: 0;
  border: none;
  background: transparent;
  border-radius: 50%;
  transition: all 0.2s linear;
  padding: 0px;
  margin: 0px 10px;
}
.shiftTimeLineCalendarDatesToggle i {
  margin: 0px 0px 2px;
}

/* PostCSS auto adds focus on hover */
.shiftTimeLineCalendarDatesToggle:focus {
  background: transparent;
}
.shiftTimeLineCalendarDatesToggle:hover {
  background: var(--grey4);
}

.shiftTimeLineCalendarContainer {
  margin: 0px;
}

.shiftTimeLineCalendarContent {
  position: relative;
}

.shiftTimeLineCalendarSubHeader {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.shiftTimeLineCalendarSubHeaderDay {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid var(--grey4);
  position: relative;
}

.shiftTimeLineCalendarStats {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  padding: 0 20px;
  background: #fff;
  overflow: visible;
  z-index: 3;
}

.shiftTimeLineCalendarStatsTitle {
  margin-top: 20px;
  margin-bottom: 5px;
  line-height: 16px;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-secondary);
}

.shiftTimeLineCalendarStatsValue {
  text-align: right;
  font-size: 10px;
  font-weight: normal;
  color: var(--text-secondary);
}

.shiftTimeLineCalendarStatsProgress {
  margin-bottom: 10px !important;
  margin-top: 0 !important;
}
.shiftTimeLineCalendarStatsProgress > .bar {
  background: #f05252 !important;
}

.shiftTimeLineCalendarTimeRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--bgWhite);
  /*border-bottom: solid 1px #bababa80;*/
}

.shiftTimeLineCalendarTimeItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 10px 5px;
  border-left: 1px dashed #d2d2d2;
  background: var(--bgWhite);
  height: 100%;
}

.shiftTimeLineCalendarTimeItem:hover {
  background: var(--grey1);
}

.shiftTimeLineCalendarTime {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-primary);
  white-space: nowrap;
}

.shiftTimeLineCalendarRow {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--bgWhite);
  transition: all 0.2s linear;
}
.shiftTimeLineCalendarRow:hover {
  background: #f5f5f5;
}

.shiftTimeLineCalendarRow:hover .shiftTimeLineCalendarGridClickable {
  background: #f5f5f5;
}
.shiftTimeLineCalendarRow:hover .shiftTimeLineCalendarGridBefore {
  background: #eceff7;
}
.shiftTimeLineCalendarRow:hover .shiftTimeLineCalendarSeparatorGridBefore {
  background: #f3f6fe;
}

.shiftTimeLineCalendarRowEmpty {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shiftTimeLineCalendarTitle {
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 10px 0 40px;
  /*background: var(--bgWhite);*/
  z-index: 4;
}

.shiftTimeLineCalendarTitleSeparator {
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 0 0 20px;
  background: var(--bgWhite);
  z-index: 4;
}

.shiftTimeLineCalendarContnet {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--bgWhite);
  overflow: hidden;
}

.shiftTimeLineCalendarGrid,
.shiftTimeLineCalendarGridClickable {
  border-left: 1px dashed var(--grey5);
  border-left: 1px dashed #d2d2d2;
  height: 64px;
  text-align: center;
  overflow: hidden;
  flex-shrink: 0;
  transition: background 0.2s linear, opacity 0.2s linear;
}

.shiftTimeLineCalendarGridClickable:hover {
  background: #f3f6fe;
}
.shiftTimeLineCalendarGridClickable:hover:before {
  content: '+ New Shift';
  font-size: 10px;
  color: #6f89b1;
  width: 100%;
  position: relative;
  top: 10px;
}

.shiftTimeLineCalendarGridBefore {
  background: #f3f6fe;
}

.shiftTimeLineCalendarSeparator {
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 5;
}

.shiftTimeLineCalendarSeparatorText {
  padding-right: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #6f89b1;
}

.shiftTimeLineCalendarSeparatorLine {
  width: 100%;
  height: 1px;
  background: var(--grey5);
}

.shiftTimeLineCalendarIndicator {
  position: absolute;
  top: 0px;
  bottom: 0;
  z-index: 6;
}

.shiftTimeLineCalendarIndicatorHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  border-radius: 8px;
  font-size: 11px;
  font-weight: normal;
  color: #fff;
  background-color: #f05252;
  position: relative;
  bottom: -36px;
}

.shiftTimeLineCalendarIndicatorLine {
  margin-left: 20px;
  width: 1px;
  height: 100%;
  background: #f05252;
}

/* .shiftGroupedCalendar {
} */

.shiftSummaryCalendarTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shiftSummaryCalendarTitle:hover .shiftSummaryCalendarName {
  color: #000000;
}

.shiftSummaryCalendarTitle:before {
  display: block;
  content: ' ';
  border-radius: 2px;
  margin-left: 9px;
  width: 4px;
  height: 47px;
  background: var(--blue);
  transition: all 0.2s linear;
}

.shiftSummaryCalendarTitleDraft:before {
  background: var(--text-primary);
}

.shiftSummaryCalendarTitleFilled:before {
  background: var(--filled);
}

.shiftSummaryCalendarName {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-primary);
  transition: all 0.2s linear;
}

.shiftSummaryCalendarNameLine {
  display: flex;
  align-items: center;
}

.shiftSummaryCalendarBlockIcon {
  padding: 0px 8px;
  height: 16px;
  z-index: 10;
}

.shiftSummaryCalendarPosition {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: var(--text-primary);
}

.shiftSummaryCalendarLocation {
  display: block;
  font-size: 12px;
  font-weight: normal;
  color: var(--blue-alt);
}
.shiftSummaryCalendarContentContainer {
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shiftSummaryCalendarContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  height: 44px;
  background: #1665d8;
  color: #fff;
  transition: all 0.2s linear;
}
.shiftSummaryCalendarContent:hover {
  box-shadow: 0 6px 5px 0 var(--shadowCard);
}

.shiftSummaryCalendarContent--Draft {
  background: rgba(62, 63, 66, 0.5);
}
.shiftSummaryCalendarContent--Pending,
.shiftSummaryCalendarContent--Draft {
  background-color: #bcbcbc88;
  color: #3e3f4288;
}
.shiftSummaryCalendarContent--Draft:hover {
  background-color: #bcbcbccc;
}
.shiftSummaryCalendarContent--Filled {
  background: var(--filled);
}
.shiftSummaryCalendarContent--Completed {
  background: var(--steel);
}

.shiftSummaryCalendarContentTiny {
  flex-direction: column;
  justify-content: center;
}

.shiftSummaryCalendarContentTitle {
  padding-left: 13px;
  font-size: 14px;
  font-weight: normal;
}

.shiftSummaryCalendarContentTitleTiny {
  font-size: 12px;
  padding-left: 0px;
}

.shiftSummaryCalendarContentProgress {
  margin: 0 0 0 16px !important;
  width: 118px;
}

.shiftSummaryCalendarContentProgressTiny {
  margin: 2px 10% 4px !important;
  width: 80%;
}

.shiftPartnersCalendarTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
}
.shiftPartnersCalendarTitle:hover .shiftPartnersCalendarName {
  color: #000000;
}

.shiftPartnersCalendarAvatar {
  display: block;
  border-radius: 50%;
  width: 43px;
  height: 43px;
}

.shiftPartnersCalendarName {
  font-size: 12px;
  font-weight: bold;
  color: var(--text-primary);
  transition: all 0.2s linear;
}

.shiftPartnersCalendarRating {
  height: 20px;
  margin-top: -5px;
}

.shiftPartnersHours {
  font-size: 12px;
  color: #6b6c6f;
}

.shiftPartnersCalendarContentContainer {
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shiftPartnersCalendarContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--borderMid);
  height: 51px;
  color: #fff;
  border-radius: 5px;
  background: #1665d8;
  transition: all 0.2s linear;
}
.shiftPartnersCalendarContent:hover {
  box-shadow: 0 6px 5px 0 var(--shadowCard);
}

.shiftPartnersCalendarContent--Pending,
.shiftPartnersCalendarContent--Draft {
  background-color: #bcbcbc;
  color: var(--grey10);
  opacity: 0.45;
}
.shiftPartnersCalendarContent--Pending:hover,
.shiftPartnersCalendarContent--Draft:hover {
  opacity: 0.75;
}
.shiftPartnersCalendarContent--Filled {
  background: var(--filled);
}
.shiftPartnersCalendarContent--Completed {
  background: var(--steel);
}

.shiftPartnersCalendarContentTitle {
  display: block;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shiftPartnersCalendarContentLocation {
  display: block;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shiftPartnersCalendarContentPopup {
  padding: 0 !important;
  width: 150px;
}

.shiftPartnersCalendarContentPopupMenu {
  padding: 0 15px;
  line-height: 38px;
  border-bottom: 1px solid var(--borderMid);
}

.shiftPartnersCalendarContentPopupMenu:last-child {
  border-bottom: 0;
}

.shiftPartnersCalendarContentPopupText {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.shiftPartnersCalendarContentPopupMenu:nth-child(2)
  .shiftPartnersCalendarContentPopupText {
  color: var(--red);
}

/* Calendar/Partners/Week view */
.shiftTimelineCalendarWeekPartnerRow {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: stretch;
  border-bottom: solid 1px #bababa80;
  background: var(--bgWhite);
  transition: background 0.2s linear, opacity 0.2s linear;
}
.shiftTimelineCalendarWeekPartnerRow:hover,
.shiftTimelineCalendarWeekPartnerRowHover {
  opacity: 1 !important;
  background: var(--bgLightestBlue);
  margin-top: -1px;
  border-bottom: solid 1px #9ab2cc80;
  border-top: solid 1px #9ab2cc80;
}

.shiftTimelineCalendarWeekPartnerRow:hover .shiftTimeLineCalendarGridClickable {
  background: var(--bgLightestBlue);
}

.shiftTimeLineCalendarAssignmentsRow {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /*background: var(--bgWhite);*/
  overflow: hidden;
}

.shiftPartnersCalendarAssignmentContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px 0 0; /* bottom 28px if prevDayOvernight */
  /*transition: all 0.2s linear;*/
}

.shiftTimeLineCalendarTimeItem {
  position: relative;
  align-items: center;
}
.shiftTimeLineCalendarWeekTime {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-primary);
  white-space: nowrap;
  text-align: center;
}

.shiftTimeLineCalendarWeekDay {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
  white-space: nowrap;
  text-align: center;
  transition: all 0.2s linear;
}

.shiftTimeLineCalendarWeekDay:hover {
  color: var(--text-tertiary);
}

.shiftTimeLineCalendarTodayBadge {
  position: absolute;
  left: 10px;
  bottom: 5px;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: normal;
  color: #f05252;
  display: none;
}

.shiftPartnersCalendarWeekContent {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-left: 1px dashed #d2d2d2;
  position: relative;
  /*transition: all 0.2s linear;*/
}

.weekPopupContent {
  border: solid 1px #417ed9 !important;
  box-shadow: 0px 0px 80px -20px rgba(0, 0, 0, 0.75) !important;
  color: var(--text-secondary);
  margin: 0px !important;
  padding: 0px !important;
  min-width: 360px !important;
}

.weekPopupShiftHeader {
  font-size: 16px;
  font-weight: bold;
  color: #2670d3;
  max-width: 400px;
}
.weekPopupShiftTitle {
  margin-top: 4px;
  margin-bottom: 4px;
}
.weekPopupShiftHeader:hover .weekPopupShiftTitle {
  text-decoration: underline;
}

.weekPopupShiftStatus {
  height: 24px !important;
  padding: 0 12px !important;
}

.weekPopupContentStats {
  background: #e5f2ff;
  padding: 10px;
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}

.weekPopupStat {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 10px 20px;
}

.weekPopupStatValue {
  font-size: 16px;
  font-weight: bold;
  color: #2670d3;
  margin: 2px 0;
}

.weekPopupStatLabel {
  color: var(--text-secondary);
}

.shiftPopupIcon {
  margin: 0px 4px;
}

.shiftPopupIcon:hover img {
  filter: brightness(50%) saturate(180%);
}

.shiftPopupSemanticIcon {
  color: var(--text-secondary);
}

.shiftPopupSemanticIcon:hover {
  color: var(--text-primary);
}

.shiftPopupIconDivider {
  border: solid 1px #e7e7e7;
  margin: 0px 6px 4px;
  align-self: stretch;
}

.shiftPartnersCalendarAssignment {
  position: relative;
  overflow: hidden;
  background: #1665d8;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;
  flex-basis: 28px; /* 24px if prevDayOvernight */
  flex-shrink: 1;
  transition: all 0.2s linear;
  min-height: 2px;
  pointer-events: all; /* Allows clicking overnight shifts */
}
.shiftPartnersCalendarAssignment:hover {
  flex-shrink: 0;
  box-shadow: 0 5px 8px 0 rgba(0%, 0%, 0%, 0.18);
}

.shiftPartnersCalendarAssignment--Pending,
.shiftPartnersCalendarAssignment--Draft {
  background-color: #bcbcbc;
  color: var(--grey10);
  opacity: 0.45;
}
.shiftPartnersCalendarAssignment--Draft:hover {
  opacity: 0.75;
}
.shiftPartnersCalendarAssignment--Filled {
  background: var(--filled);
}
.shiftPartnersCalendarAssignment--Completed {
  background: var(--completed);
}

.shiftPartnersCalendarAssignmentTarget {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, var(--grey6) 50%, transparent 50%),
    linear-gradient(0deg, var(--grey6) 50%, transparent 50%),
    linear-gradient(90deg, var(--grey6) 50%, transparent 50%),
    linear-gradient(0deg, var(--grey6) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-size: 15px 2px, 2px 15px, 15px 2px, 2px 15px;
  background-position: left top, right top, left bottom, left top;
  color: var(--grey6);
  border-radius: 5px;
  margin-bottom: 2px;
  flex-basis: 28px; /* 24px if prevDayOvernight */
  flex-shrink: 1;
  transition: all 0.2s linear;
  min-height: 20px;
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: border-dance 8s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: left top, right top, right bottom, left 1000%;
  }
  100% {
    background-position: right top, right 1000%, left bottom, left top;
  }
}

.shiftPartnersCalendarWeekContentTitle {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: inherit;
  cursor: pointer;
}

.shiftTimelineCalendarOpenShiftsHeader {
  height: calc(100% - 58px);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid var(--grey4);
  position: relative;
}
.shiftTimelineCalendarOpenShiftsHeaderGradient {
  position: absolute;
  height: 34px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background-image: linear-gradient(
    180deg,
    rgba(238, 238, 238, 0) 0%,
    rgba(216, 216, 216, 0.35) 100%
  );
  pointer-events: none;
}

.shiftTimelineCalendarOpenSlotsTitle {
  flex-shrink: 0;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: var(--text-primary);
  padding: 10px 20px;
  background: #fff;
  position: relative;
}

.openSlotsTitleDayContainer {
  position: sticky;
  top: -7px;
  z-index: 6;
  width: 100%;
}

.shiftTimelineCalendarOpenSlotsTitleDay {
  flex-shrink: 0;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: var(--blue);
  padding: 10px;
  margin: 7px 7px 0px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.shiftTimelineCalendarOpenSlotsTitleDay:hover {
  text-decoration: underline;
}
.shiftTimelineCalendarOpenSlotsTitleDay:hover
  .shiftTimelineCalendarDayOpenSlotsToggle {
  text-decoration: none;
}

.shiftTimelineCalendarOpenSlotsToggle {
  position: absolute;
  right: 4px;
  opacity: 0.6 !important;
}
.shiftTimelineCalendarOpenSlotsToggle:hover {
  opacity: 1 !important;
}

.shiftTimelineCalendarDayOpenSlotsToggle {
  position: relative;
  top: -2px;
  margin-left: 4px !important;
  opacity: 0.6 !important;
}
.shiftTimelineCalendarDayOpenSlotsToggle:hover {
  opacity: 1 !important;
  text-decoration: none !important;
}

.shiftTimelineCalendarOpenShiftsBox {
  border-left: 1px dashed #d2d2d2;
  overflow-x: hidden;
  overflow-y: scroll;
}
.shiftTimelineCalendarOpenShiftsBox::-webkit-scrollbar * {
  background: transparent !important;
}

.shiftTimelineCalendarOpenShiftRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: var(--bgWhite);
  position: relative;
}

.shiftPartnersWeekOpenShiftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px 0 0; /* bottom 28px if prevDayOvernight */
}

.shiftPartnersWeekOpenShiftDayCount {
  margin-top: -4px;
  font-style: italic;
  color: var(--blue);
  cursor: pointer;
}

.shiftPartnersWeekOpenShiftDayCount:hover {
  text-decoration: underline;
}

.openShift {
  position: relative;
  min-height: 28px;
  padding: 4px 4px 4px 10px;
  width: calc(100% - 30px);
  margin-left: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: visible;
  box-shadow: 0 5px 8px 0 rgba(0%, 0%, 0%, 0.18);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: box-shadow 0.2s linear;
  cursor: grab;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.openShiftDay {
  position: relative;
  min-height: 28px;
  padding: 4px 4px 4px 10px;
  width: calc(100% - 30px);
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 20px;
  border-radius: 4px;
  overflow: visible;
  box-shadow: 0 5px 8px 0 rgba(0%, 0%, 0%, 0.18);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: box-shadow 0.2s linear;
  cursor: grab;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.openShift:hover,
.openShiftDay:hover,
.openShiftPreview {
  box-shadow: 0 7px 10px 0 rgba(0%, 0%, 0%, 0.25);
}

.openShift--Active {
  background: #1665d8;
  color: #fff;
}

.openShift--Draft {
  background: #bcbcbc6b;
  color: #3e3f42c9;
}

.openShift--Expired {
  background: var(--red-25);
  color: #3e3f42c9;
}

.openShiftSlotsCount {
  position: absolute;
  top: -1em;
  left: -1em;
}

.openShiftOvernightSlotsCount {
  font-size: 10px;
  font-weight: bold;
  padding: 2px 8px;
  background-color: var(--red);
  color: #f1f1f1;
  border-radius: 0 50% 50% 0;
  position: absolute;
  left: 0;
  top: -33px;
}

.openShiftContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  position: relative;
}

.openShiftsTitle {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.openShiftsTitle:hover {
  text-decoration: underline;
}

.openShiftGrabHandle {
  flex-shrink: 0;
  flex-grow: 0;
  height: 14px;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding-left: 4px;
}

.openShiftPreviewTime {
  opacity: 0.5;
  font-style: italic;
}

.emptyState {
  position: relative;
  background-image: linear-gradient(white, #f1f1f1);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-primary);
  padding: 10px 0;
  margin: 25px;
}

.dividerLine {
  width: 49px;
  height: 2px;
  border: solid 1px #979797;
  margin: 10px 0;
}

.filtersContainer {
  background-color: #eaedf3;
  box-shadow: inset 0 0 1px 0 #9ab2cc;
  position: sticky;
  top: 0;
  z-index: 8;
}

.selectionsContainer {
  padding: 8px 38px 8px 20px;
  background-color: #eaedf3;
  box-shadow: inset 0 0 1px 0 #9ab2cc;
  position: sticky;
  top: 0;
  z-index: 7;
}

.filterSearchBar {
  width: 275px;
  position: relative;
}

.filterHint {
  margin-left: 15px;
  position: absolute;
  left: 10px;
  top: 10px;
  color: var(--grey10);
  animation-delay: 1s;
}

.filterButton,
.selectionButton {
  margin-right: 15px;
}

.shiftHeaderRangeTabs {
  right: 38px;
  height: 32px;
  background: var(--bgLightestBlue);
}

.shiftHeaderRangeTab {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: 60px;
  height: 28px;
  transition: all 0.2s linear;
}

.shiftHeaderRangeTab:hover {
  background: rgba(100%, 100%, 100%, 0.4);
}
.shiftHeaderRangeTabSelected,
.shiftHeaderRangeTab:focus {
  background: #fff;
}

.partnerSearch {
  position: absolute;
  bottom: 10px;
  left: 20px;
  width: 240px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.partnerSort {
  font-size: 12px;
}

.partnerSortLabel {
  font-weight: normal;
  color: var(--text-meta);
}

.shiftPartnersPinIcon {
  position: absolute;
  top: 24px;
  left: 20px;
  display: none;
  color: var(--text-tertiary);
}

.shiftPartnersPinIconActive {
  display: block;
}

.shiftPartnersPinIcon:hover,
.shiftPartnersPinIconActive {
  color: var(--blue);
}

.shiftPartnersPinIconActive:hover {
  color: var(--text-tertiary);
}

.shiftTimelineCalendarWeekPartnerRow:hover .shiftPartnersPinIcon,
.shiftTimelineCalendarWeekPartnerRowHover .shiftPartnersPinIcon {
  display: block;
}

.unpinAllButton {
  font-size: 10px;
  font-weight: normal;
  color: var(--blue);
  padding: 2px 8px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border: solid 1px #bababa80;
  border-top: none;
  box-shadow: 0 2px 16px 0 rgba(127, 133, 139, 0.2);
  position: absolute;
  bottom: -24px;
  left: 12px;
  z-index: 8;
}

.shiftPartnersDayOpenShiftContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  background: #fff;
  border-left: 1px dashed #d2d2d2;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 20px;
}

.shiftPartnersCalendarDayAssignmentTarget {
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(90deg, var(--grey6) 50%, transparent 50%),
    linear-gradient(0deg, var(--grey6) 50%, transparent 50%),
    linear-gradient(90deg, var(--grey6) 50%, transparent 50%),
    linear-gradient(0deg, var(--grey6) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-size: 15px 2px, 2px 15px, 15px 2px, 2px 15px;
  background-position: left top, right top, left bottom, left top;
  color: var(--grey6);
  border-radius: 5px;
  margin-top: 7px;
  flex-basis: 28px; /* 24px if prevDayOvernight */
  flex-shrink: 1;
  transition: all 0.2s linear;
  min-height: 50px;
  font-style: italic;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: border-dance-day 8s infinite linear;
}

@keyframes border-dance-day {
  0% {
    background-position: left top, right top, right bottom, left 500%;
  }
  100% {
    background-position: right top, right 500%, left bottom, left top;
  }
}

.statsFooter {
  height: 110px;
  background: #fff;
  display: flex;
  flex-direction: row;
  box-shadow: 0 -5px 8px -3px rgba(0, 0, 0, 0.17);
  position: absolute;
  bottom: 0;
  z-index: 6;
}

.statsFooterColumn {
  border-left: 1px dashed #d2d2d2;
}

.statsFooterSlotsSummaryCell {
  height: 46px;
  border-top: 1px solid var(--grey4);
  font-size: 14px;
  color: var(--grey10);
}

.statsFooterCostSummaryCell,
.statsFooterHoursSummaryCell {
  height: 28px;
  border-top: 1px solid var(--grey4);
  font-size: 14px;
  color: var(--grey10);
}

.statsFooterLabel {
  font-size: 12px;
  font-weight: bold;
  color: var(--grey10);
  align-self: flex-end;
  padding: 4px 20px;
}

.statsFooterSlotsCell {
  height: 46px;
  border-top: 1px solid var(--grey4);
  font-size: 14px;
  color: var(--grey10);
  padding: 8px 14px 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statsFooterCostCell,
.statsFooterHoursCell {
  height: 28px;
  border-top: 1px solid var(--grey4);
  font-size: 14px;
  color: var(--grey10);
  padding: 4px 14px;
}

.statsFooterEmptyMessage {
  font-size: 12px;
  color: var(--text-secondary);
}

.weekCalandarContainer {
  height: calc(100% - 186px);
}

.filterSeparator {
  margin-right: 15px;
  margin-left: 15px;
  width: 1px;
  border-radius: 3px;
  background-color: var(--text-tertiary);
}

.shiftActionsButton {
  composes: vars;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #c2dbff;
  background-color: var(--bgLightestBlue);
  color: var(--text-primary);
  transition: var(--fastTransition);
}
