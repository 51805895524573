.su {
  min-height: 400px;
  margin-left: 256px;
}

.content {
  padding-top: 0;
  display: block;
  height: 100%;
  /* background-color: var(--pageContentBG); */
  overflow-y: hidden;
}
