@charset "UTF-8";

/* Experimenting with Fontastic custom icon font-family */
/* http://fontastic.me/ */
@font-face {
  font-family: 'shiftsmart-icons';
  src: url('shiftsmart-icons.eot');
  src: url('shiftsmart-icons.eot?#iefix') format('embedded-opentype'),
    url('shiftsmart-icons.woff') format('woff'),
    url('shiftsmart-icons.ttf') format('truetype'),
    url('shiftsmart-icons.svg#shiftsmart-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'shiftsmart-icons' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*=' icon-'] {
  display: flex;
  justify-content: center;
  align-items: center;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'shiftsmart-icons' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ssm-checkmark:before {
  content: '\61';
}

.icon-ssm-eye:before {
  content: '\62';
}

.icon-ssm-check-out:before {
  content: '\63';
  margin-top: 0.125em;
  /* Add margin top to center with text */
}

.icon-ssm-check-in:before {
  content: '\64';
  margin-top: 0.125em;
}

/* Experimenting with SVG as CSS Masks for font-size based, scalable, colorable icons */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/mask */
.ssm-check-in {
  height: calc(1em);
  width: calc(17em / 15);
  background-color: currentColor;
  -webkit-mask-image: url('/static/img/icons/ssm-check-in.svg');
  mask-image: url('/static/img/icons/ssm-check-in.svg');
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top left;
  mask-position: top left;
}

.ssm-check-out {
  height: calc(1em);
  width: calc(17em / 15);
  background-color: currentColor;
  -webkit-mask-image: url('/static/img/icons/ssm-check-out.svg');
  mask-image: url('/static/img/icons/ssm-check-out.svg');
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top left;
  mask-position: top left;
}

.ssm-pencil {
  height: 1em;
  width: 1em;
  background-color: currentColor;
  -webkit-mask-image: url('/static/img/icons/ssm-pencil.svg');
  mask-image: url('/static/img/icons/ssm-pencil.svg');
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top left;
  mask-position: top left;
}

.ssm-row-shrink {
  height: 1em;
  width: 1em;
  background-color: currentColor;
  -webkit-mask-image: url('/static/img/icons/ssm-row-shrink.svg');
  mask-image: url('/static/img/icons/ssm-row-shrink.svg');
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top left;
  mask-position: top left;
}

.ssm-row-expand {
  height: 1em;
  width: 1em;
  background-color: currentColor;
  -webkit-mask-image: url('/static/img/icons/ssm-row-expand.svg');
  mask-image: url('/static/img/icons/ssm-row-expand.svg');
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: top left;
  mask-position: top left;
}
