.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-left: 20px;
  padding: 0 30px;
  height: 32px;
  background-color: #b1b1b2;
}

.container.indicator {
  height: 14px;
  width: 14px;
  border-radius: 100%;
  padding: 0;
  margin: 0 0 0 0;
}

.container.mini {
  height: 18px;
  border-radius: 3px;
  padding: 0 6px;
}

.container.small {
  height: 24px;
  border-radius: 4px;
  padding: 0 10px;
}

.container.fluid {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.text {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.container.small > .text {
  font-size: 12px;
}

.container.mini > .text {
  font-size: 10px;
}

.active {
  background-color: var(--active);
}

.redundant {
  background-color: var(--active);
}

.backup {
  background-color: var(--orange-75);
}

.shadow {
  background-color: var(--violet-75);
}

.completed {
  background-color: var(--completed);
}

.filled {
  background-color: var(--filled);
}

.deleted,
.canceled,
.expired {
  background-color: var(--red);
}

/** Button Overrides */
.canceled.basic {
  box-shadow: 0 0 0 1px var(--red-50) !important;
}

.canceled.basic > i {
  color: var(--red) !important;
}
