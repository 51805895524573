.container {
  background: var(--pageContentBG);
}

.overView {
  border-top: 1px solid var(--borderMid);
  background: var(--bgWhite);
}

.overViewRow {
  padding: 20px 30px 12px;
}

.avatarContainer {
  position: relative;
  overflow: hidden;
  padding: 0;
  border-color: var(--blue-alt-3);
  flex-direction: row;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.avatar {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.statusDot {
  position: absolute;
  top: -4px;
  right: -8px;
  border-radius: 100%;
  border: 4px solid #fff;
  width: 24px !important;
  height: 24px !important;
  color: var(--green);
  background-color: var(--green);
}

.overViewCenter {
  padding-left: 15px;
}

.displayName {
  font-size: 24px;
  line-height: 24px;
  font-weight: normal;
  color: var(--blue-alt);
}

.rating {
  margin-left: 36px;
  width: 100px;
  height: 12px;
}

.overViewDescription {
  padding-top: 12px;
}

.overViewDescriptionCenter {
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.overViewDescriptionColumn {
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.partnerRoles > .actionButton {
  opacity: 0;

  transition: opacity 0.3s;
  transition-delay: 0s;
}

.partnerRoles:hover > .actionButton {
  opacity: 1;
  transition-delay: 500ms;
}

.overViewBottom {
  padding: 12px 35px;
}

.rolesTitle {
  padding-right: 16px;
  font-size: 14px;
  color: var(--text-primary);
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  flex-shrink: 0;
  position: relative;
}

.tabMenu {
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
}

#tabItem,
#tabItemActive {
  flex: 1;
  justify-content: center;
  border-radius: 0;
  white-space: nowrap;
  background: transparent;
}

#tabItem {
  font-weight: normal;
  border-bottom: 1px solid var(--grey5) !important;
}

#tabItemActive {
  border-bottom: 3px solid #2670d3 !important;
  font-weight: bold;
  color: var(--blue-alt);
}

#tabItem:before,
#tabItemActive:before {
  display: none;
}

.tabContent {
  border-radius: 0 0 4px 4px;
  border: 1px solid var(--borderMid);
  border-top: 0;
  margin-top: -1rem;
  background: var(--bgWhite);
}

/* .summary {
} */

.summaryColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 30px 15px;
  height: calc(100vh - 290px);
  background: var(--bgWhite);
  border: 1px solid var(--borderMid);
  box-shadow: var(--boxShadowCard);
}

.RoleBadgeColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--bgWhite);
  border: 1px solid var(--borderMid);
  box-shadow: var(--boxShadowCard);
}

.RoleBadgeColumn:not(:last-child) {
  margin-bottom: 15px;
}

.RoleBadgeContainerColumn {
  flex: 1;
  margin: 30px 15px 0px 15px;
  height: calc(100vh - 290px);
}

.summaryColumn:first-child {
  margin-left: 30px;
}
.summaryColumn:last-child {
  margin-right: 30px;
}

.summaryColumnContent {
  padding: 0px 15px 10px;
}

.summaryTitle {
  padding: 15px;
}

.summaryTitleText {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  text-transform: uppercase;
}
.summaryTitleText > i.icon {
  margin-right: 4px !important;
}

.summaryColumnAction:hover img {
  filter: brightness(50%) saturate(180%);
}

.badge {
  margin-top: 12px;
  margin-left: 26px;
  width: 52px;
  flex-grow: 0;
}

.badgeContainer {
  min-height: 60px;
}

.badgeImage {
  width: 52px;
}

.badgeText {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: var(--text-primary);
}

.statistics {
  padding: var(--padding2) var(--padding3);
  height: 50%;
}

.statsPanel {
  padding: 8px 4px;
  height: 50%;
  min-height: 500px;
  max-height: 100%;
  background: var(--bgWhite);
  border: 1px solid var(--borderMid);
  box-shadow: var(--boxShadowCard);
  overflow: hidden;
  flex-grow: 1;
}

.statsPanelLeft {
  width: 100%;
  height: 100%;
}

.statsPanelTitle {
  display: block;
  padding-top: 14px;
  padding-left: 22px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  color: var(--text-primary);

  height: 100%;
}

.statsPanelLeft .statsPanelTabs {
  padding-top: var(--padding1);
  padding: 0 20px;
  border-bottom: 1px solid var(--grey5);
}

.statsPanelLeft .statsMainContent {
  padding: var(--padding3) var(--padding2);
}

.statsPanelTab {
  margin-bottom: -1px;
  height: 80px;
  color: #818181;
}

.statsPanelTabActive {
  border-bottom: 3px solid var(--blue-alt);
  height: 82px;
  color: var(--text-meta-alt);
}

.statsPanelLabel {
  display: block;
  line-height: 22px;
  font-size: 14px;
  font-weight: bold;
}

.statsPanelValue {
  display: block;
  line-height: 38px;
  font-size: 26px;
  font-weight: bold;
}

.statsPanelDesc {
  display: block;
  line-height: 18px;
  font-size: 12px;
  font-weight: normal;
}

.statsPanelRight {
  width: 100%;
  height: 100%;

  overflow-y: scroll;

  border-left: 1px solid var(--grey5);
  padding: var(--padding3) var(--padding2);
  width: 275px;
  flex-grow: 0;
}

.statsPanelRightRow {
  padding-top: 30px;
}

.statsPanelRightLabel {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
}

.statsPanelRightValue {
  display: block;
  padding-top: 5px;
  font-size: 20px;
  font-weight: normal;
  color: var(--text-primary);
}

.statsCommentsPanel {
  margin-top: 24px;
  padding: 18px 22px;
  background: var(--bgWhite);
  border: 1px solid var(--borderMid);
  box-shadow: var(--boxShadowCard);
}

.statsCommentsTitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-primary);
}

.statsCommentTag {
  margin: 22px 6px 0;
  padding: 0 18px;
  height: 32px;
  border-radius: 19px;
  background-color: #769ed7;
}

.statsCommentTagText {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.statsDropdownButton {
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--borderDarkAlt);
  margin-right: 30px;
  padding: 0 12px;
  width: 91px;
  height: 38px;
  box-shadow: var(--boxShadowButtonBasic);
}

.statsDropdownButtonText {
  flex: 1;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.statsDropdown {
  position: absolute;
  top: 43px;
  left: 0;
  border-radius: 4px;
  border: 1px solid var(--borderDarkAlt);
  width: 90px;
  background: var(--bgWhite);
  box-shadow: var(--boxShadowButtonBasic);
  z-index: 999;
}

.statsDropdownItem {
  border-bottom: 1px solid var(--borderDarkAlt);
  padding-left: 12px;
  height: 38px;
  line-height: 38px;
}

.statsDropdownItem:last-child {
  border-bottom: 0;
}

.statsDropdownItemText {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
}

.shifts {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--borderMid);
  background: var(--bgWhite);
  box-shadow: var(--boxShadowCard);
  overflow: hidden;
}

.shiftsHeader {
  border-bottom: 1px solid #f1f1f1;
}

.shiftsTabs {
  flex: 1;
  height: 50px;
}

.shiftsTab {
  width: 135px;
  text-align: center;
  font-weight: normal;
  color: var(--text-primary);
}

.shiftsTabActive {
  border-bottom: 3px solid var(--blue-alt);
  width: 135px;
  height: 50px;
  text-align: center;
  font-weight: bold;
  color: var(--blue-alt);
}

.shiftsTabText {
  line-height: 50px;
  font-size: 14px;
}

.shiftsTimezone {
  margin-right: 26px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
}

.shiftsTimezoneLabel {
  color: var(--grey6);
}

.shiftsTimezoneValue {
  color: var(--blue-alt);
}

.shiftsDateRange {
  border: 0;
  padding: 0;
  height: 25px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
}

.shiftsMode {
  margin-left: 18px;
  margin-right: 10px;
  width: 120px;
  height: 41px;
  border-radius: 27.5px;
  background-color: var(--bgLightestBlue);
}

.shiftsModeButton {
  width: 57px;
  height: 34px;
}

.shiftsModeButtonActive {
  width: 57px;
  height: 34px;
  border-radius: 30px;
  background-color: var(--bgWhite);
}

.shiftsList {
  overflow-y: auto;
  height: 100%;
}

.shiftsEmptyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--borderMid);
  padding-left: 27px;
  height: 3rem;
}

.shiftsEmptyTitle {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--text-primary);
}

.shiftLogo {
  border-radius: 50%;
  margin-left: 13px;
  width: 58px;
  height: 58px;
  box-shadow: 0 3px 4px 0 #e3e3e9;
}

.shiftLogoPlaceholder {
  width: 58px;
  height: 58px;
}

.shiftRate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  width: 29px;
  height: 29px;
  background: url('/static/img/icon-shift-time-based.png') no-repeat;
  background-size: cover;
  font-size: 15px;
  font-weight: normal;
  color: var(--blue);
}

.payments {
  padding-bottom: 20px;
  overflow-y: scroll;
}

.paymentsHeaderItem {
  background-color: var(--bgWhite);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 25px;
  margin: 10px;
  box-shadow: 0 11px 9px -6px rgba(197, 197, 197, 0.38);
}

.totalPayment {
  color: #34aa44;
}

.outstanding {
  color: #1665d8;
}

.shiftsmart {
  color: var(--text-secondary);
}

.instantPayment {
  color: var(--text-secondary);
}

.paymentsHeaderItemTitle {
  font-size: 14px;
  margin-bottom: 15px;
}

.paymentsHeaderItemAmount {
  font-size: 30px;
  margin-bottom: 5px;
}

.paymentsHeaderItemInstant {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-meta-alt);
}

.paymentsHeaderItemInstantOff {
  color: var(--red-50);
}

.partnerTransactionListItemTitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--blue-alt);
}

.partnerTransactionListItemDate {
  font-size: 12px;
  font-weight: bold;
  color: var(--text-primary);
}

.paymentRow {
  border-bottom: 1px solid var(--borderMid);
  margin: 0 35px;
  height: 52px;
}

.paymentRow:last-child {
  border-bottom: 0;
}

.paymentIcon {
  display: flex !important;
  border-radius: 50%;
  margin-right: 13px !important;
  width: 30px !important;
  height: 30px !important;
  color: var(--blue-alt-3);
  background: #f2f5fb;
}

.paymentText {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-primary);
}

.paymentTextHighlight {
  color: var(--blue-alt-3);
  text-decoration: underline;
}

.workExperience {
  border: 1px solid var(--borderMid);
  box-shadow: var(--boxShadowCard);
  background: var(--bgWhite);
}

.education {
  margin-top: 38px;
  border: 1px solid var(--borderMid);
  box-shadow: var(--boxShadowCard);
  background: var(--bgWhite);
}

.workAndEduTitle {
  padding: 15px 30px;
}

.mainTitle {
  font-weight: 600;
  font-size: 16;
}

.workAndEduRow {
  border-bottom: 1px solid var(--grey5);
  padding: 0 25px;
  height: 70px;
}

.workAndEduRow:last-child {
  border-bottom: 0;
}

.workAndEduBorder {
  border-bottom: 1px solid var(--grey5);
}

.workAndEduBorder:last-child {
  border-bottom: 0;
}

.workAndEduIconContainer {
  border-radius: 50%;
  margin-right: 17px;
  width: 58px;
  height: 58px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
}

.workAndEduIcon {
  max-width: 75%;
  max-height: 75%;
  border-radius: 50%;
}

.workExperienceName,
.educationName {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: var(--blue-alt);
}

.workExperienceCompany,
.educationGrade {
  display: block;
  padding-top: 5px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.workExperienceDate,
.workExperienceLocation,
.educationDate {
  line-height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.workExperienceDesc,
.educationDesc {
  padding: 20px 30px !important;
  min-height: 124px;
  background-color: #f4f4f4;
}

.certificates {
  background: var(--bgWhite);
  border: 1px solid var(--borderMid);
  box-shadow: var(--boxShadowCard);
}

.certificatesTitle {
  padding: 23px 30px;
}

.certificateRow {
  border-bottom: 1px solid var(--grey5);
  padding: 15px 25px;
  min-height: 75px;
  cursor: pointer;
}

.certificateRowSmall {
  padding: 10px 10px;
}

.certificateRow:last-child {
  border-bottom: 0;
}

.certificateTitle {
  line-height: 22px;
  font-size: 18px;
  font-weight: 500;
  color: var(--blue-alt);
}

.certificateTitleSmall {
  line-height: 16px;
  font-size: 18px;
  font-weight: 400;
}

.certificateDesc {
  line-height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.certificateDescSmall {
  padding-top: 10px;
  line-height: 12px;
  font-size: 14px;
}

.certificateVerified {
  font-size: 12px;
  font-weight: 600;
  color: #83d133;
}

.certificateValidButton {
  width: 84px;
  height: 32px;
  background-color: var(--bgLightestBlue);
  color: var(--blue-alt);
  padding: 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.certificateValidButtonSmall {
  width: 64px;
  height: 30px;
  font-size: 10px;
}

.certificateInvalidButton {
  width: 84px;
  height: 32px;
  background-color: var(--red);
  color: #ffffff;
  padding: 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.certificateInvalidButtonSmall {
  width: 64px;
  height: 30px;
  font-size: 10px;
}

.certificateUnverifiedButton {
  width: 95px;
  height: 32px;
  background-color: var(--text-primary);
  opacity: 0.25;
  color: #ffffff;
  padding: 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.certificateUnverifiedButtonSmall {
  width: 75px;
  height: 30px;
  font-size: 10px;
}

.certificateDate {
  padding-left: 8px;
  min-width: 85px;
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.certificateDateSmall {
  padding-left: 6px;
  min-width: 50px;
  font-size: 12px;
}

.certificateDatePlaceholder {
  width: 130px;
}

.certificateButton {
  border-radius: 4px;
  border: 1px solid var(--borderDarkAlt);
  margin-left: 44px;
  width: 137px;
  height: 38px;
  box-shadow: var(--boxShadowButtonBasic);
  cursor: pointer;
}

.certificateButtonPlaceholder {
  width: 137px;
  height: 38px;
}

.certificateButtonText {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.certModalImageContainer {
  background-color: #ebebeb;
}

.certModalImagePicker {
  background-color: rgba(33, 97, 198, 0.73);
  margin-top: -120px;
  height: 120px;
  z-index: 1;
}

.certModalImagePlaceholder {
  height: 500px;
}

.certModalImageScroller {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.certModalAddImage {
  height: 100px;
  width: 100px;
  background-color: white;
  font-size: 11px;
  color: #7188b4;
}

.certModalTitle {
  font-size: 20px;
  color: #1f6dd7;
  padding-right: 10px;
}

.certModalIssuer {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
  padding-bottom: 15px;
}

.certModalDates {
  font-size: 14px;
  color: var(--grey6);
}

.certModalNotesLabel {
  font-size: 12px;
  color: var(--text-secondary);
  padding-bottom: 5px;
}

.cerModalNotesTitle {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
  color: var(--text-primary);
}

.certModalNotes {
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary);
  word-break: break-word;
  text-align: justify;
}

.tagModalFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 14px;
}

.tagModalText {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-secondary);
}

.tagModalText strong {
  color: var(--text-meta-alt);
}

.tagModalTextNegative {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-secondary);
}

.tagModalTextNegative strong {
  color: #ff4136;
}

.tagModalAvatar {
  border-radius: 50%;
  margin-left: 9px;
  width: 38px;
  height: 38px;
}

.instantPaymentModalTitle {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-primary);
}

.instantPaymentModalSection {
  padding: 10px 0px;
}

.instantPaymentModalSectionTitle {
  color: var(--text-secondary);
  margin-bottom: 10px;
}

.instantPaymentModalSectionEnable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--text-primary);
}

.instantPaymentModalSectionEnableText {
  padding-right: 15px;
}

.poolCount {
  background: #fbfbfd;
  padding: 10px 30px 10px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: var(--text-primary);
  position: sticky;
  top: 0px;
  z-index: 10;
}

.paginationFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f1f1f1;
  padding: 10px 15px 15px;
  flex-shrink: 0;
}
