@import "../colors";

$logic-space: 20px;

.svd-logic-tab {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.svd-logic-tab__rules-list {
  width: 100%;
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans",
    "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  text-align: left;
  color: #404040;
  border-collapse: collapse;
}

.svd-logic-tab__rules-list__header {
  font-weight: bold;
}

.svd-logic-tab__rules-list__row {
  border-bottom: solid 1px $secondary-border-color;
  border-bottom-color: var(--secondary-border-color, $secondary-border-color);
  th,
  td {
    padding: $logic-space / 2;
  }
}

.svd-logic-tab__rules-list__action-cell {
  text-align: center;
  & > * {
    cursor: pointer;
  }
}

.svd-logic-tab__rule-editor__title {
  font-size: 20px;
  font-weight: bold;
  color: #404040;
}

.svd-logic-tab__item {
  border: solid 1px $secondary-border-color;
  border-color: var(--secondary-border-color, $secondary-border-color);
  padding: $logic-space;
  margin-bottom: $logic-space;
  // div:nth-child(2) {
  //   padding-top: $logic-space / 2;
  // }
}

.svd-logic-tab__item-header {
  font-size: 16px;
  font-weight: bold;
  color: #404040;
  line-height: 34px;
}

.svd-logic-tab__item-comtent {
  padding-top: $logic-space;
}

.svd-logic-tab__item-header-icon {
  float: right;
  cursor: pointer;
  padding-right: 10px;
}

.svd-logic-tab-item-header {
  background-color: #e0e0e0;
  min-height: 32px;
  line-height: 32px;
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 14px;
  padding: 0 1em;

  .svd-logic-header-item-icon {
    float: right;
    cursor: pointer;
    padding-right: 10px;

    svg-icon {
      display: inline-block;
      width: 16px;
      height: 16px;

      svg {
        display: block;
        margin-top: 3px;
      }
    }

    .svd-logic-header-ext-icon-text {
    }
  }
  .svd-logic-header-item-text {
  }
}

.svd_container .svd_content .svd-logic-tab {
  .svd-expression-header {
    font-size: 16px;
    font-weight: bold;
    color: #404040;
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
  }
  .ext-icon-expression-arrow {
    margin-right: $logic-space / 2;
  }
  .svd-propertyeditor-condition {
    border: solid 1px $secondary-border-color;
    border-color: var(--secondary-border-color, $secondary-border-color);
    padding: $logic-space;
  }
  .svd-expression-content {
    margin-top: $logic-space;
  }
  .form-group {
    margin-bottom: 0;
  }
  .btn {
    height: 34px;
  }
  .btn-link {
    border: solid 1px $secondary-border-color;
    border-color: var(--secondary-border-color, $secondary-border-color);
  }

  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .svd-danger-icon .svd-svg-icon {
    fill: $error-color;
    fill: var(--error-color, $error-color);
  }
}
