/* @type */
.pageMenu {
  height: 62px;
  min-height: 62px;
  max-height: 62px;
  background-color: transparent !important;
  font-family: Roboto, inherit !important;
  font-weight: 200, inherit !important;
  color: #666 !important;
  margin-bottom: 0 !important;
  flex-shrink: 0;

  display: flex;
  align-items: center;
}

.pageMenu .item {
  font-size: 12px !important;
  border-bottom-width: 1px !important;
  height: 100%;
}

.pageMenu > * {
  height: 100%;
}

.pageMenu .item:not(.active) {
  margin-bottom: -1px !important;
}

.pageMenu .item.active {
  color: #444 !important;
  font-size: 12px !important;
  border-bottom-width: 3px !important;
  border-bottom-color: var(--blue-alt-3) !important;
  height: 100%;
}

.divider {
  width: 1px;
  margin-right: 12px;

  background-color: var(--borderDark);
  height: 28px;
}

.divider:only-child,
.divider:last-child,
.divider:first-child {
  display: none;
}
