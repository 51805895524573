

.navigationGroupEmptySpace {
  min-height: 25px;
}

.activeTriangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 0;
  border-color: transparent #ffffff transparent transparent;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  position: absolute;
  right: 0px;
  top: 24px;
}

.adminBar .activeTriangle {
  top: 12px;
}
