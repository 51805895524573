/** Global Colors
 * The color variables contained in the `:root` class are available in any context.
 * To use, wrap the variable name in a `var` css function. eg:
 *  > background-color: var(--red-25) !important
*/

/** Style guide for choosing colors:
  *  --black for the most important text on the page
  *  --grey for text of less importance (subtitles) or micro-actions
  *  --blue for clickable buttons/actions
  *  --blue-navy for filters
  *  --red for most attention (errors, urgency)
  *  --orange for a bit less attention
  *  --yellow for even less attention (warnings)
 */

:root {
  /** Global Color Vars per http://zpl.io/29OAjDd */
  --blue: #1a71cf;
  --blue-75: #1a71cfcc;
  --blue-50: #1a71cf88;
  --blue-25: #1a71cf44;
  --blue-10: #1a71cf19;
  --green: #19a974;
  --green-75: #19a974cc;
  --green-50: #19a97488;
  --green-25: #19a97444;
  --violet: #6758f3;
  --violet-75: #6758f3cc;
  --violet-50: #6758f388;
  --violet-25: #6758f344;
  --red: #e6492d;
  --red-75: #e6492dcc;
  --red-50: #e6492d88;
  --red-25: #e6492d44;
  --orange: #f6ab2f;
  --orange-75: #f6ab2fcc;
  --orange-50: #f6ab2f88;
  --orange-25: #f6ab2f44;
  --yellow: #facf55;
  --yellow-75: #facf55cc;
  --yellow-50: #facf5588;
  --yellow-25: #facf5544;
  --steel: #7188b4;
  --steel-75: #7188b4cc;
  --steel-50: #7188b488;
  --steel-25: #7188b444;

  --white: #fff;

  --ssm-blue: #2a6cc4;

  /** Alt Colors */
  --blue-alt: #2670d3;
  --blue-alt-2: #4471a0;
  --blue-alt-3: #2a6cc4;
  --blue-alt-4: #2077d6;
  --blue-alt-5: #8096ae;
  --blue-alt-6: #2655c1;

  --blue-navy: #0c3272;

  --grey1: #f6f9fd;
  --grey2: #f5f5f5;
  --grey3: #f1f1f1;
  --grey3_5: #fbfbfd;

  --grey4: #d8d8d8;

  --grey5: #bababa;

  --grey6: #9ea0a5;
  --grey7: #8a8b8c;
  --grey8: #838383;

  --grey10: #3e3f42;
  --grey11: #e6e5ec;

  --red-alt: #cd2b3f;

  /** Text Colors */
  --text-primary: #3e3f42;
  --text-secondary: #6b6c6f;
  --text-tertiary: #bcbcbc;
  --text-meta: #9ea0a5;
  --text-meta-alt: #1665d8;
  --text-meta-alt2: #212122;

  /** Shadows */

  --shadowCard: rgba(197, 197, 197, 0.38);
  --shadowDark: rgba(0, 0, 0, 0.5);

  --shadowPrimary: rgba(142, 184, 255, 0.5);
  --shadowBasic: rgba(0, 0, 0, 0.08);
  --shadowHeader: rgba(127, 133, 139, 0.2);

  /** Borders */
  --borderLight: var(--grey3);
  --borderMid: #eaedf3;
  --borderDarkAlt: #d8dce6;
  --borderDark: #d3d8e0;

  /** Gradients */
  --gradientPrimary: linear-gradient(259deg, #2755c2, var(--blue));
  --gradientSecondary: linear-gradient(254deg, #8b8b8b, #5a5a5a);
  --gradientTertiary: linear-gradient(252deg, var(--grey3), var(--grey3));

  /** Backgrounds */
  --pageContentBG: #fafafa;
  --bgInfo: #f3f9ff;

  --bgWhite: #ffffff;
  --bgLightest: var(--grey1);
  --bgLight: var(--grey3);

  --bgLightestBlue: #e5f2ff;
  --bgLightBlue: #9ab2cc;

  /* Shift Status Colors */
  --active: #1665d8;
  --filled: #19a974;
  --completed: var(--steel);
  --draft: #bcbcbc;

  /* Suggestions UI Colors */
  --bgSuggestion: #fffbf9;
  --borderSuggestion: #e69c3c;

  /* Onboarding added colors*/
  --success: #32a623;
}

.meta {
  color: var(--text-meta) !important;
}

.metaAlt {
  color: var(--text-meta-alt) !important;
}

.ssm-text {
  color: var(--text-primary) !important;
}
.ssm-text-secondary {
  color: var(--text-secondary) !important;
}
.ssm-text-tertiary {
  color: var(--text-tertiary) !important;
}

.ssm-blue {
  color: var(--blue) !important;
}
.ssm-blue-75 {
  color: var(--blue-75) !important;
}
.ssm-blue-50 {
  color: var(--blue-50) !important;
}
.ssm-blue-25 {
  color: var(--blue-25) !important;
}
.ssm-green {
  color: var(--green) !important;
}
.ssm-green-75 {
  color: var(--green-75) !important;
}
.ssm-green-50 {
  color: var(--green-50) !important;
}
.ssm-green-25 {
  color: var(--green-25) !important;
}
.ssm-violet {
  color: var(--violet) !important;
}
.ssm-violet-75 {
  color: var(--violet-75) !important;
}
.ssm-violet-50 {
  color: var(--violet-50) !important;
}
.ssm-violet-25 {
  color: var(--violet-25) !important;
}
.ssm-red {
  color: var(--red) !important;
}
.ssm-red-75 {
  color: var(--red-75) !important;
}
.ssm-red-50 {
  color: var(--red-50) !important;
}
.ssm-red-25 {
  color: var(--red-25) !important;
}
.ssm-orange {
  color: var(--orange) !important;
}
.ssm-orange-75 {
  color: var(--orange-75) !important;
}
.ssm-orange-50 {
  color: var(--orange-50) !important;
}
.ssm-orange-25 {
  color: var(--orange-25) !important;
}
.ssm-yellow {
  color: var(--yellow) !important;
}
.ssm-yellow-75 {
  color: var(--yellow-75) !important;
}
.ssm-yellow-50 {
  color: var(--yellow-50) !important;
}
.ssm-yellow-25 {
  color: var(--yellow-25) !important;
}
.ssm-steel {
  color: var(--steel) !important;
}
.ssm-steel-75 {
  color: var(--steel-75) !important;
}
.ssm-steel-50 {
  color: var(--steel-50) !important;
}
.ssm-steel-25 {
  color: var(--steel-25) !important;
}

.ssm-grey1 { color: var(--grey1) !important }
.ssm-grey2 { color: var(--grey2) !important }
.ssm-grey3 { color: var(--grey3) !important }
.ssm-grey3_5 { color: var(--grey3_5) !important }

.ssm-grey4 { color: var(--grey4) !important }

.ssm-grey5 { color: var(--grey5) !important }

.ssm-grey6 { color: var(--grey6) !important }
.ssm-grey7 { color: var(--grey7) !important }
.ssm-grey8 { color: var(--grey8) !important }

.ssm-grey10 { color: var(--grey10) !important }
.ssm-grey11 { color: var(--grey11) !important }

.ssm-bg-blue {
  background-color: var(--blue) !important;
}
.ssm-bg-blue-75 {
  background-color: var(--blue-75) !important;
}

.ssm-bg-blue-75.ui {
  background-color: var(--blue-75) !important;
}
.ssm-bg-blue-50 {
  background-color: var(--blue-50) !important;
}
.ssm-bg-blue-25 {
  background-color: var(--blue-25) !important;
}
.ssm-bg-blue-25.ui {
  background-color: var(--blue-25) !important;
}
.ssm-bg-green {
  background-color: var(--green) !important;
}
.ssm-bg-green-75 {
  background-color: var(--green-75) !important;
}
.ssm-bg-green-50 {
  background-color: var(--green-50) !important;
}
.ssm-bg-green-25 {
  background-color: var(--green-25) !important;
}
.ssm-bg-violet {
  background-color: var(--violet) !important;
}
.ssm-bg-violet-75 {
  background-color: var(--violet-75) !important;
}
.ssm-bg-violet-50 {
  background-color: var(--violet-50) !important;
}
.ssm-bg-violet-25 {
  background-color: var(--violet-25) !important;
}
.ssm-bg-red {
  background-color: var(--red) !important;
}
.ssm-bg-red-75 {
  background-color: var(--red-75) !important;
}
.ssm-bg-red-50 {
  background-color: var(--red-50) !important;
}
.ssm-bg-red-25 {
  background-color: var(--red-25) !important;
}
.ssm-bg-orange {
  background-color: var(--orange) !important;
}
.ssm-bg-orange-75 {
  background-color: var(--orange-75) !important;
}
.ssm-bg-orange-50 {
  background-color: var(--orange-50) !important;
}
.ssm-bg-orange-25 {
  background-color: var(--orange-25) !important;
}
.ssm-bg-yellow {
  background-color: var(--yellow) !important;
}
.ssm-bg-yellow-75 {
  background-color: var(--yellow-75) !important;
}
.ssm-bg-yellow-50 {
  background-color: var(--yellow-50) !important;
}
.ssm-bg-yellow-25 {
  background-color: var(--yellow-25) !important;
}
.ssm-bg-steel {
  background-color: var(--steel) !important;
}
.ssm-bg-steel-75 {
  background-color: var(--steel-75) !important;
}
.ssm-bg-steel-50 {
  background-color: var(--steel-50) !important;
}
.ssm-bg-steel-25 {
  background-color: var(--steel-25) !important;
}



.ssm-bg-grey1 { background-color: var(--grey1) !important }
.ssm-bg-grey2 { background-color: var(--grey2) !important }
.ssm-bg-grey3 { background-color: var(--grey3) !important }
.ssm-bg-grey3_5 { background-color: var(--grey3_5) !important }

.ssm-bg-grey4 { background-color: var(--grey4) !important }

.ssm-bg-grey5 { background-color: var(--grey5) !important }

.ssm-bg-grey6 { background-color: var(--grey6) !important }
.ssm-bg-grey7 { background-color: var(--grey7) !important }
.ssm-bg-grey8 { background-color: var(--grey8) !important }

.ssm-bg-grey10 { background-color: var(--grey10) !important }
.ssm-bg-grey11 { background-color: var(--grey11) !important }

.ssm-bg-lightest-blue { background-color: var(--bgLightestBlue) !important }
