.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1999;
}

.widget {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -300px;
  margin-top: -250px;
  width: 768px;
  border-radius: 6px;
  background-color: var(--white);
  box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.headerWrapper {
  padding: 8px;
  background-color: #dfeeff;
}

.header {
  border-radius: 4px;
  border: 1px solid transparent;
  height: 52px;
  background-color: var(--white);
}

.input {
  display: inline-block;
  border: none;
  padding: 0 5px;
  width: 100%;
  height: 100%;
  line-height: 1.4em;
  font-size: 22px;
  color: #3d3d3d;
  resize: none;
}

.input:focus {
  outline: 1px solid var(--blue);
  outline-offset: 0;
}

.list {
  overflow-x: hidden;
  padding: 8px 4px;
  width: 100%;
  max-height: 450px;
  line-height: 45px;
  white-space: nowrap;
  color: #ccc;
}

.item {
  overflow: hidden;
  border-top: solid 1px #979797;
  padding: 0 6px;
  width: 100%;
  height: 45px;
  cursor: pointer;
  touch-action: none;
}

.item:first-child {
  border-top: 0;
}

.item:hover:not(.selected) {
  background: #dfeeff;
}

.selected {
  background: #062f4a;
}

.label {
  margin: 0 0 0 5px;
  height: 100%;
  cursor: pointer;
}

.labelName {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  color: #3d3d3d;
  font-size: 16px;
  white-space: pre;
}

.entryKeybinding {
  margin-right: 8px;
}

.keybinding {
  line-height: 24px;
}

.key {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border-radius: 2px;
  min-width: 25px;
  font-size: 13px;
  font-weight: normal;
  padding: 0 8px;
  margin-left: 8px;
  color: var(--white);
  background-color: var(--text-meta-alt);
}

.keySeparator {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  line-height: 24px;
  font-size: 19px;
  font-weight: normal;
  color: #3d3d3d;
}

.groupSeparator {
  margin-right: -8px;
}
