.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
button.rbc-btn {
  overflow: visible;
  text-transform: none;
  appearance: button;
  cursor: pointer;
}
button[disabled].rbc-btn {
  cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-rtl {
  direction: rtl;
}
.rbc-off-range {
  color: #999999;
}
.rbc-off-range-bg {
  background: #e5e5e5;
}
.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* padding: 0 3px; */
  text-align: center;
  vertical-align: middle;
}
/* .rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
} */
.rbc-week {
  line-height: 35px;
  font-size: 12px;
  font-weight: 500;
  color: var(--grey6);
}
.rbc-date {
  line-height: 35px;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-primary);
}
.rbc-row-content {
  position: relative;
  user-select: none;
  z-index: 4;
}
/* .rbc-today {
  background-color: #eaf6ff;
} */
.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}
.rbc-event {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 10px 10px 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-event-short-duration {
  padding: 8px 8px 0;
}
.rbc-event-title {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rbc-event-title-short {
  margin-bottom: 0;
}
.rbc-event-desc {
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui.popup.rbc-event-popup-semantic {
  background: var(--bgLightestBlue) !important;
  padding: 0;
  border: none;
}
.ui.popup.rbc-event-popup-semantic:before {
  background: var(--bgLightestBlue) !important;
  border: none;
}
.rbc-event-popup {
  position: relative;
  top: 0px;
  border-radius: 4px;
  padding: 0 18px;
  width: 325px;
  height: 300px;
  background: var(--bgLightestBlue);
  z-index: 1;
  cursor: default;
}
.rbc-event-popup-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 41px;
}
.rbc-event-popup-title {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}
.rbc-event-popup-close {
  font-size: 16px;
  color: var(--blue-alt-2);
  z-index: 1;
  cursor: pointer;
  outline: none;
}

.rbc-event-popup-content {
  border-radius: 4px;
  background: var(--bgWhite);
}
.rbc-event-popup-content-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--grey5);
  padding: 10px 0;
}
.rbc-event-popup-content-title {
  display: block;
  padding-top: 5px;
  font-size: 18px;
  font-weight: 500;
  color: var(--blue-alt);
}
.rbc-event-popup-content-company {
  display: block;
  padding-top: 7px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}
.rbc-event-popup-content-address {
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}
.rbc-event-popup-content-bottom {
  padding-left: 86px;
  padding-bottom: 14px;
}
.rbc-event-popup-content-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 13px;
}
.rbc-event-popup-content-row-text {
  margin-left: 12px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-secondary);
}
.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
/* .rbc-event.rbc-selected {
  background-color: #265985;
} */
/* .rbc-event:focus {
  outline: 5px auto #3b99fc;
} */
.rbc-event-label {
  font-size: 80%;
}
.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-row {
  display: flex;
  flex-direction: row;
}
.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap;
}
.rbc-month-view {
  position: relative;
  border: 1px solid var(--borderMid);
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  height: 100%;
}
.rbc-month-header {
  display: flex;
  flex-direction: row;
}
.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid var(--borderMid);
}
.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}
.rbc-day-bg {
  flex: 1 0 0%;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid var(--borderMid);
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid var(--borderMid);
}
.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: var(--bgWhite);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.rbc-overlay > * + * {
  margin-top: 1px;
}
.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid var(--borderMid);
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid var(--borderMid);
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid var(--borderMid);
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid var(--borderMid);
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid var(--borderMid);
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' »';
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '« ';
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}
.rbc-agenda-event-cell {
  width: 100%;
}
.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 20px 0;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}
.rbc-timeslot-group {
  min-height: 54px;
  display: flex;
  flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}
.rbc-label {
  font-size: 12px;
  font-weight: 500;
  color: var(--grey6);
}
.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-is-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event {
  border-left: 3px solid var(--blue-alt-3);
  margin-left: 1px;
  display: flex;
  max-height: 100%;
  min-height: 54px;
  flex-flow: column wrap;
  align-items: flex-start;
  position: absolute;
}
.rbc-day-slot .rbc-event-assigned {
  border-left: 3px solid var(--blue-alt-3);
  color: var(--blue-alt-3);
  background: #e9f0f8;
}
.rbc-day-slot .rbc-event-complete {
  border-left: 3px solid #35ab44;
  color: #35ab44;
  background: #eaf6ec;
}
.rbc-day-slot .rbc-event-available {
  border-left: 3px solid #838383;
  color: #838383;
  background: #f1f1f1;
}
.rbc-day-slot .rbc-event-noshow {
  border-left: 3px solid #f8cf19;
  color: #f8cf19;
  background: #fffae6;
}
.rbc-day-slot .rbc-event-conflict {
  border: 2px solid #ececec;
  border-radius: 5px;
  color: #838383;
  cursor: not-allowed;
  background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 4px,
      transparent 1px,
      #ececec 7px
    ),
    linear-gradient(to bottom, transparent, transparent);
}
.rbc-day-slot .rbc-event-label {
  display: none;
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  /* border-right: 1px solid var(--borderMid); */
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}
.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}
.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}
.rbc-timeslot-group {
  min-height: 35px;
}
.rbc-time-slot {
  flex: 1 0 0;
  text-align: center;
  width: 80px;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}
.rbc-day-header {
  text-align: center;
}
.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
.rbc-slot-selecting {
  cursor: move;
}
.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
  display: none;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid var(--borderMid);
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}
.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid var(--borderMid);
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid var(--borderMid);
}
.rbc-time-header-cell-single-day {
  display: none;
}
.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid var(--borderMid);
}
.rbc-time-content {
  display: flex;
  flex: 1 1 0%;
  border-top: 1px solid var(--borderMid);
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid var(--borderMid);
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid var(--borderMid);
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
}
/* .rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
} */

.rbc-template-calendar.rbc-calendar {
  overflow-y: visible;
}

.rbc-template-calendar .rbc-row-segment {
  height: 8px;
}

.rbc-template-calendar .rbc-event {
  padding: 0 !important;
  padding-left: 2px !important;
  background: transparent;
}

.rbc-template-calendar .rbc-shift-event {
  height: 6px;
  border-radius: 3px;
  background: var(--bgLightBlue);
  color: transparent;
}
.rbc-template-calendar .rbc-shift-event--Draft {
  opacity: 0.4;
}
.rbc-template-calendar .rbc-shift-event--Draft:hover {
  opacity: 1;
}

.rbc-template-calendar .rbc-shift-event:hover {
  height: 6px;
  border-radius: 3px;
  background: var(--blue);
  color: transparent;
}

.rbc-template-calendar .rbc-date-cell {
  padding: 4px 0 4px 8px;
  text-align: left;
}

.rbc-template-calendar .rbc-header {
  border-bottom: solid 1px #d8dce6;
  text-transform: uppercase;
  color: #9ea0a5;
}

.rbc-template-calendar .rbc-show-more {
  color: var(--bgLightBlue);
  margin-top: -2px;
  margin-left: 2px;
}
.rbc-template-calendar .rbc-show-more:hover {
  color: var(--blue);
}

.rbc-template-calendar .rbc-overlay .rbc-shift-event {
  height: 20px;
  padding-left: 6px;
  border-radius: 3px;
  background: transparent;
  color: var(--blue);
  overflow: hidden;
}
.rbc-template-calendar .rbc-overlay .rbc-shift-event:hover {
  height: 20px;
  border-radius: 3px;
  background: transparent;
  color: var(--bgLightBlue);
}

.rbc-template-calendar .rbc-overlay-header {
  padding: 8px 16px;
}
