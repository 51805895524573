.container {
  height: 100%;
  background: #fbfbfd;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 20px 30px 10px;
  background: #fbfbfd;
  position: sticky;
  top: 0;
  z-index: 10;
}

.headerTitle {
  font-size: 26px;
  font-weight: 300;
  color: var(--text-primary);
}

.headerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 38px;
  border-radius: 4px;
}

.headerButton:nth-child(1) {
  box-shadow: var(--boxShadowButtonBasic);
  border: solid 1px var(--borderDarkAlt);
  background-color: var(--bgWhite);
}

.headerButton:nth-child(1) .headerButtonText {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.headerButton:nth-child(2) {
  margin-left: 17px;
  box-shadow: var(--boxShadowButtonPrimary);
  background-image: var(--gradientPrimary);
}

.headerButton:nth-child(2) .headerButtonText {
  font-size: 15px;
  font-weight: normal;
  color: var(--bgWhite);
}

.footer {
  padding: 15px 0;
  background: #fbfbfd;
}

.footerActions {
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left {
  margin-left: 30px;
  margin-top: 15px;
  width: 57%;
  border: 1px solid var(--borderMid);
  box-shadow: var(--boxShadowCard);
}

.formContainer {
  max-width: 1080px;
  margin: 15px auto 0;
}

.form {
  /* TODO: Use standard padding values per styleguide
  /* padding: var(--padding3) var(--padding3) var(--padding2) var(--padding3); */
  padding: 20px 35px 25px 23px;
}

.form:nth-child(1) {
  border-radius: 4px 4px 0 0;
}

.formTitle {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-primary);
}

/** `!important` forces override of semantic-ui styles */
.formLabel,
.form label {
  margin-top: 14px !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  text-transform: uppercase !important;
  color: var(--text-secondary) !important;
}

.dateTime {
  border-radius: 3px;
  padding: 2px 20px;
  background-color: var(--bgLight);
}

.dateTime label {
  color: var(--grey6) !important;
}

.date {
  position: relative;
}

.date:before {
  position: absolute;
  top: 9px;
  left: 10px;
  display: block;
  content: ' ';
  width: 18px;
  height: 20px;
  background: url('/static/img/icon-calendar-grey.png') no-repeat;
  background-size: contain;
}

.date input {
  padding-left: 35px !important;
  width: inherit !important;
}

.date:not(.dateOnly) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.time {
  flex: 0.5;
  min-width: 95px;
}

.time input {
  width: inherit !important;
}

.time:not(.timeOnly) input {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.removeButton {
  flex: 0 0 20px;
  height: 20;
  margin: 15px -10px 0px 12px;
}

.advancedOptionsButton {
  border-radius: 0 0 4px 4px;
  padding: 21px 0 !important;
  background: var(--bgWhite);
}

.advancedOptionsButtonText {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-meta-alt);
}

.advancedOptionsButtonIcon {
  display: block;
  margin-left: 14px;
  width: 6px;
  height: 10px;
  transform: rotate(0deg);

  transition: transform 0.3s ease-out;
}

.advancedOptionsButtonIconRotated {
  transform: rotate(-180deg);
}

.advancedOptions {
  border-radius: 0 0 4px 4px;
}

.advancedOptions label {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: var(--text-secondary) !important;
}

.advancedOptionsField {
  flex: 1;
  padding: 14px 0 20px;
}

.advancedOptionsFieldFluid {
  border-bottom: 1px solid var(--grey5);
}

.advancedOptionsFieldFluid .advancedOptionsField {
  border-bottom: 0;
}

.right {
  width: 39%;
  flex-grow: 1;
}

.invitationsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 15px;
}

.partnersTitle {
  font-size: 22px;
  font-weight: 300;
  color: var(--text-primary);
}

.assignPartnersList {
  max-height: 260px;
}

.invitationsTitle {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-primary);
  padding-bottom: 10px;
  padding-top: 15px;
}

.invitationsButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #d8dce6;
  padding: 0 40px;
  height: 50px !important;
  background: var(--bgWhite);
}

.invitationsButtonText {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.invitationsItemHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -10px;
}

.invitationsItemTitle {
  font-size: 17px;
  font-weight: 500;
}

.invitationsItemTitle strong {
  color: #979797;
}

.invitationsItemInput {
  margin-right: 10px;
  width: 76px;
}

.invitationsItemDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d8dce6;
  padding: 0 14px;
  height: 38px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--bgWhite);
}

.invitationsItemDropdownText {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.invitationsItemContent {
  border-radius: 0 4px 4px 0;
  margin-top: 10px;
  box-shadow: 0 11px 9px -6px rgba(197, 197, 197, 0.38);
  border-left: 5px solid;
  padding: 20px 15px 25px;
  background: var(--bgWhite);
}

.invitationsItemContent.noBorder {
  border-left: none;
}

.invitationsItemEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 172px;
}

.invitationsItemEmptyText {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: normal;
  color: var(--text-primary);
}

.invitationsItemCount {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-primary);
}

.invitationsItemAddButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  border: 1px solid #d8dce6;
  margin-left: 10px;
  padding: 0 20px;
  height: 38px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background: var(--bgWhite);
}

.invitationsItemAddButton span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.invitationsItemInviteButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  border: 1px solid #2d9c3c;
  margin: 0 5px;
  width: 127px;
  height: 38px;
  box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
  background: #6ab253;
}

.invitationsItemInviteButton span {
  font-size: 14px;
  font-weight: bold;
  color: var(--bgWhite);
}

.invitationsItemColumn {
  border-right: 1px solid #eaedf3;
  width: 16.6%;
}

.invitationsItemColumn:last-child {
  border-right: 0;
}

.invitationsItemAvatarPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #1665d8;
  width: 38px;
  height: 38px;
  background: var(--bgWhite);
  z-index: 4;
}

.invitationsItemAvatarPlaceholder span {
  font-size: 14px;
  font-weight: 500;
  color: #1665d8;
}

.invitationsItemPoolAvatar {
  border-radius: 50%;
  display: block;
  margin-left: -4px;
  width: 38px;
  height: 38px;
}

.invitationsItemPoolName {
  padding: 8px 2em 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #2670d3;
}

.invitationsItemPoolLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  margin-top: 10px;
  padding: 0 16px;
  height: 28px;
  background: #e5f2ff;
}

.invitationsItemPoolLabel span {
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
  color: var(--text-primary);
}

.invitationsItemAvatar {
  border-radius: 50%;
  display: flex;
  width: 80px;
  height: 80px;
}
.invitationsItemWorkerName {
  display: block;
  padding-top: 14px;
  font-size: 18px;
  font-weight: 500;
  color: #2670d3;
}

.pagination {
  font-size: 14px;
  font-weight: 500;
  color: #9ea0a5;
}

.pagination em {
  font-style: normal;
  color: #1665d8;
}

.invitationsItemRequest {
  padding: 10px;
  border-left-color: var(--blue-alt);
}

.invitationsItemRequestContent {
  border-radius: 6px;
  padding: 25px 12px 25px 25px;
  background: var(--bgLightestBlue);
}

.invitationsItemRequestTitle {
  font-size: 17px;
  font-weight: 500;
  color: var(--blue);
}

.invitationsItemRequestRow div:nth-child(1n),
.invitationsItemRequestRow div:nth-child(2n) {
  width: 25%;
}

.invitationsItemRequestRow div:nth-child(3n) {
  width: 50%;
}

.invitationsItemRequestLabel {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.invitationsItemRequestValue {
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.invitationsItemCancelButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  border: 1px solid #d8dce6;
  margin-left: 10px;
  padding: 0 55px;
  height: 38px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background: var(--bgWhite);
}

.invitationsItemCancelButton span {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}

.invitation {
  margin-bottom: 10px;
}

.invitationSeparator {
  display: block;
  content: ' ';
  margin-bottom: -3px;
  height: 45px;
  width: 5px;
  background: var(--grey4);
}

.invitationGroupsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
}

.invitationGroupWrapper {
  width: 50%;
  position: relative;
}

/* Sets the --color variable to cycle sequentially for selectors within shiftInvitationGroup.css */
.invitationGroupWrapper:nth-of-type(1) {
  --color: var(--green);
}
.invitationGroupWrapper:nth-of-type(5n + 2) {
  /*--color: #16c2d6;*/
  --color: #25abd6;
}
.invitationGroupWrapper:nth-of-type(5n + 3) {
  /*--color: #3A86FF;*/
  --color: #3a86ff;
}
.invitationGroupWrapper:nth-of-type(5n + 4) {
  /*--color: #c04396;*/
  --color: #2d55db;
}
.invitationGroupWrapper:nth-of-type(5n + 5) {
  /*--color: #a246db;*/
  --color: #202dc0;
}
.invitationGroupWrapper:nth-of-type(5n + 6) {
  /*--color: #56228d;*/
  --color: #121b89;
}

.invitationListWrapper {
  padding: 0 60px;
}

.invitationWrapper:nth-child(1n) .invitation .invitationTitle,
.invitationWrapper:nth-child(1n) .invitationsItemTitle {
  color: var(--blue-alt);
}

.invitationWrapper:nth-child(1n) .invitation .invitationContent,
.invitationWrapper:nth-child(1n) .invitationsItemContent {
  border-left-color: var(--blue-alt);
}

.invitationWrapper:nth-child(2n) .invitation .invitationTitle,
.invitationWrapper:nth-child(2n) .invitationsItemTitle {
  color: var(--green);
}

.invitationWrapper:nth-child(2n) .invitation .invitationContent,
.invitationWrapper:nth-child(2n) .invitationsItemContent {
  border-left-color: var(--green);
}

.invitationWrapper:nth-child(3n) .invitation .invitationTitle,
.invitationWrapper:nth-child(3n) .invitationsItemTitle {
  color: var(--violet);
}

.invitationWrapper:nth-child(3n) .invitation .invitationContent,
.invitationWrapper:nth-child(3n) .invitationsItemContent {
  border-left-color: var(--violet);
}

.invitationTitle {
  font-size: 17px;
  font-weight: 500;
}

.invitationHeaderDropdown {
  margin-left: 7px;
}

.invitationButton {
  border-radius: 4px;
  border: 1px solid var(--borderDarkAlt);
  padding: 0 14px;
  height: 38px;
  box-shadow: var(--boxShadowButtonBasic);
  background-color: var(--bgWhite);
}

.invitationButtonText {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
}

.invitationContent {
}

.invitationSubHeader {
  padding-bottom: 18px;
}

.invitationSubTitle {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-primary);
}

.invitationSubHeaderButton {
  font-size: 12px;
  font-weight: 500;
  color: var(--grey6);
}

.invitationRow {
  padding: 8px 0;
  border-bottom: 1px solid var(--grey5);
}

.invitationRow:last-child {
  border-bottom: 0;
  padding-bottom: 40px;
}

.invitationRowTitle {
  font-size: 13px;
  font-weight: 500;
  color: var(--blue-alt);
}

.invitationRowButton {
  margin-left: 10px;
  border-radius: 6px;
  padding: 0 10px;
  height: 26px;
  background: var(--bgLightestBlue);
}

.invitationRowButtonText {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-primary);
}

.invitationPoolMore {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  border: 2px solid var(--text-meta-alt);
  background: var(--bgWhite);
}

.invitationPoolMoreText {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-meta-alt);
}

.invitationPool {
  border-radius: 50%;
  width: 38px;
  height: 38px;
}

.invitationWorker {
  border-radius: 50%;
  width: 44px;
  height: 44px;
}

.invitationWorkerMore {
  border-radius: 50%;
  margin-left: -3px;
  width: 44px;
  height: 44px;
  border: 3px solid var(--text-meta-alt);
  background: var(--bgWhite);
}

.invitationWorkerMoreText {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-meta-alt);
}

.emptyInvitations {
  padding: 15px 10px;
  color: var(--text-primary);
}

.dividerLine {
  width: 49px;
  height: 2px;
  border: solid 1px var(--grey6);
  margin: 10px 0;
}

.inviteButtonPrimary {
  border-radius: 5px;
  height: 50px !important;
  width: 75%;
  box-shadow: var(--boxShadowButtonPrimary);
  background-image: var(--gradientPrimary);
}

.inviteButtonPrimaryText {
  font-size: 15px;
  font-weight: 600;
  color: var(--bgWhite);
}

.invitationGroupHeader {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-primary);
  background: #fbfbfd;
  z-index: 9;
}

.steps {
  margin: 0;
}

.stepSeparator {
  margin: 0 8px;
  border-top: 2px dashed var(--blue-alt-2);
  width: 55px;
}

.completedStepSeparator {
  border-top: 2px solid var(--blue);
}

.step {
  border-radius: 27px;
  border: 1px solid var(--blue-alt-2);
  height: 40px;
  padding-right: 10px;
}

.stepActive {
  border-color: var(--blue);
  background: var(--blue);
}

.stepIcon {
  border-radius: 50%;
  border: 2px solid var(--blue-alt-2);
  margin-left: 6px;
  width: 30px;
  height: 30px;
  font-size: 17px;
  font-weight: 600;
  color: var(--blue-alt-2);
}

.stepActive .stepIcon {
  border-color: var(--bgWhite);
  color: var(--bgWhite);
}

.stepCompleted {
  border-color: var(--blue);
}

.stepCompleted .stepTitle {
  color: var(--blue);
}

.stepTitle {
  flex: 1;
  padding-right: 6px;
  padding-left: 10px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: var(--blue-alt-2);
}

.stepActive .stepTitle {
  color: var(--bgWhite);
}

.core {
}

.prevStep {
  width: 192px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 0 12px 14px -6px rgba(26, 113, 207, 0.38);
  background: var(--bgWhite);
  font-size: 15px;
  font-weight: 600;
  color: var(--blue);
}

.nextStep {
  width: 192px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 0 6px 5px 0 rgba(70, 85, 110, 0.5);
  background-image: var(--gradientPrimary);
  font-size: 15px;
  font-weight: 600;
  color: var(--bgWhite);
}

.invites {
  margin: 30px 0;
}

.advanced {
  margin: 0 30px 30px;
}

.summary {
  border-radius: 5px;
  border: 1px solid var(--borderMid);
  padding: 20px;
  background: var(--bgWhite);
}

.mapWrapper {
  width: 112px;
  height: 105px;
}

.summaryLogo {
  margin-right: 10px;
  display: block;
  width: 60px;
  height: 60px;
}

.summaryTitle {
  font-size: 22px;
  font-weight: normal;
  color: var(--blue-alt);
}

.summaryCompany {
  padding-right: 25px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey10);
}

.summaryValue {
  padding-left: 10px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-secondary);
}

.summaryDate {
  padding-right: 25px;
  font-size: 18px;
  font-weight: lighter;
  color: var(--text-primary);
}

.summaryTime {
  padding-right: 25px;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-secondary);
}

.summaryProgress {
  margin-bottom: 0 !important;
  margin-right: 6px !important;
  width: 82px;
}

.summaryProgressText {
  font-size: 14px;
  font-weight: normal;
  color: var(--grey10);
  margin-right: 6px;
  margin-bottom: 2px;
}

.advancedForm {
  margin-top: 43px;
  border-radius: 4px;
  border: 1px solid var(--borderMid);
  padding: 28px 34px 50px;
  background: var(--bgWhite);
}
