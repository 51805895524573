/* Override Semantic UI Styles */
.ui.secondary.pointing.menu {
  width: 100%;
  height: 62px;
  min-height: 62px;
  max-height: 62px;
  font-family: Roboto, inherit !important;
  font-weight: 200 !important;
  color: var(--text-secondary) !important;
  margin-bottom: 0 !important;
  flex-shrink: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 var(--borderMid);
  background-color: var(--bgWhite);
  border: none;
  z-index: 1;

  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}

.ui.secondary.pointing.menu .item {
  font-family: Roboto, inherit;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--text-secondary);
  height: 100%;
  border-bottom-color: transparent !important;
  border-bottom-width: 3px !important;
  align-self: flex-start;
}

.ui.secondary.pointing.menu > * {
  height: 100%;
}

.ui.secondary.pointing.menu .item:not(.active) {
  border-bottom-color: transparent !important;
}

.ui.secondary.pointing.menu .item.active {
  color: var(--blue-alt);
  border-bottom-width: 3px !important;
  border-bottom-color: var(--text-meta-alt) !important;
  height: 100%;
  font-size: 14px;
  font-weight: 500;
}

.ssm-fullwidth-tabs.ui.menu {
  margin-top: 0px;
  height: 46px !important;
  min-height: 46px !important;
  max-height: 46px !important;
}

.ssm-fullwidth-tabs.ui.menu .menu {
  width: 100%;
}
.ssm-fullwidth-tabs.ui.menu .item {
  flex-grow: 1;
  justify-content: space-around;
}
.ssm-fullwidth-tabs.ui.menu .item.active {
  color: var(--blue) !important;
}

.ssm-basic-tabs.ui.secondary.pointing.menu {
  min-height: unset;
  max-height: unset;
  height: 100%;
  box-shadow: none;
  background-color: transparent !important;
}
