.divider {
  color: #3a506b;
}

/* TEXT COLORS */

._c1 {
  color: #2a6cc4;
}

._c2 {
  color: #5790db;
}

._c3 {
  color: #999999;
}

._c4 {
  color: #1c2541;
}

._c5 {
  color: #2a6cc4;
}

/* BORDER COLORS */

._b1 {
  border-color: #2a6cc4;
}

._b2 {
  border-color: #5790db;
}

._b3 {
  border-color: #999999;
}

._b4 {
  border-color: #1c2541;
}

._b5 {
  border-color: #cccccc;
}

/* BACKGROUND COLORS */

._bg1 {
  background-color: #2a6cc4;
  color: #f2f2f2;
}

._bg2 {
  background-color: #5790db;
  color: #f2f2f2;
}

._bg3 {
  background-color: #999999;
  color: #f2f2f2;
}

._bg4 {
  background-color: #f25f5c;
  color: #f2f2f2;
}

._bg5 {
  background-color: #1c2541;
  color: #ffffff;
}

/* HOVER COLORS */

._c1:hover {
  color: #5790db;
}

._c2:hover {
  color: #5790db;
}

._c6:hover {
  color: #f25f5c;
}

._b1:hover {
  border-color: #5790db;
}

._b2:hover {
  border-color: #5790db;
}

._b6:hover {
  border-color: #f25f5c;
}
