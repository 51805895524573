/* @type */

:root {
  --fontFamilyBase: Roboto, 'Open Sans', 'avenir next', avenir, 'helvetica neue',
    helvetica, ubuntu, noto, 'segoe ui', arial, sans-serif;
}

.fonts {
  font-family: var(--fontFamilyBase);

  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.46;
}

.f1 {
  composes: fonts;

  font-size: 26px;
  font-weight: 300;
  line-height: 1.46;
  color: var(--text-primary);
}

.h1 {
  composes: f1;
  margin-bottom: 1rem;
}

.f2 {
  composes: fonts;

  font-size: 18px;
  line-height: 1.56;
  color: var(--text-secondary);
}

.h2 {
  composes: f2;
}

.h2.meta,
.h2 > .meta {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: var(--text-meta);
}

.f3 {
  composes: fonts;

  font-size: 18px;
  font-weight: 400;
  line-height: 1.56;
  color: var(--text-primary);
}

.h3 {
  composes: f3;
}

.h3subheader {
  composes: h3;

  font-size: 14px;
  font-weight: 500;
  color: var(--text-meta);
}

.f4 {
  composes: fonts;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: var(--text-secondary);
}

.h4 {
  composes: f4;

  margin-bottom: 1rem;
}

.f5 {
  composes: fonts;

  font-size: 18px;
  line-height: 1.56;
  color: var(--text-secondary);
}

.h5 {
  composes: f5;

  margin-bottom: 0.75rem;
}

.h5 > .meta {
  font-size: 18px;
  line-height: 2.11;
  color: var(--text-meta-alt);
}

.headerInline {
  padding-right: 2rem;
  margin-right: 2rem;
  border-right: 1px solid var(--borderLight);
}

.formInputLabel {
  composes: fonts;

  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--text-secondary);

  text-transform: uppercase;
  padding-bottom: 0.5rem;
}

.primary {
  composes: fonts;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: var(--text-meta-alt);
}
