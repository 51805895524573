/* @type */

.root {
  background-color: var(--bgWhite);
  margin-bottom: 0.15rem;
  box-shadow: var(--boxShadowCard);
  border: 1px solid var(--borderMid);
}

.vertical {
  composes: root;
  margin-bottom: 1rem;
}

.rootActive {
  border: 1px solid;
}
.header {
  height: 75px;
}

.headerVertical {
  height: 42px;
}

.body {
  overflow: hidden;

  /* height: 0px; */
  max-height: 0px;

  transition: max-height 0.5s ease-in;
}

.bodyExpanded {
  composes: body;

  padding-top: 0.5rem;

  height: auto;
  max-height: 66vh;

  overflow-y: scroll;
}
