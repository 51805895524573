/* @type */
@value typography: "./typography.css";
@value fonts from typography;

.vars {
  --fastTransition: box-shadow 0.2s, transform 0.2s;
  --slowTransition: box-shadow 0.4s, transform 0.4s;
}

.root {
  composes: vars;
  height: 38px;
  border-radius: 5px;
  border-width: 0;

  padding-left: 20px;
  padding-right: 20px;

  font-size: 15px;
  font-weight: 600;
  line-height: 1.27;
  font-style: normal;
  font-stretch: normal;
  /* letter-spacing: -0.5px; <- Causes blurry text depending on browser font rendering */
  text-align: center;
  transition: var(--slowTransition);

  user-select: none;
}

.root:active {
  transition: var(--fastTransition);
  transform: scale(0.97);
}

.root:disabled {
  opacity: 0.5;
}

.root.large {
  height: 42px;
  border-radius: 6px;
  padding-left: 24px;
  padding-right: 24px;

  font-size: 18px;
}

.root .img {
  -webkit-user-drag: none;
  user-drag: none;
}

.primary {
  composes: root;
  box-shadow: 0 6px 5px 0 var(--shadowPrimary);
  background-image: var(--gradientPrimary);

  font-family: var(--fontFamilyBase);

  color: #ffffff;
}

.primary:hover {
  box-shadow: 0 6px 8px 4px var(--shadowPrimary);
}

.primary:active {
  box-shadow: 0 2px 1.7px 0 var(--shadowPrimary);
}

.secondary {
  composes: root;
  box-shadow: 0 6px 5px 0 var(--shadowCard);
  background-image: var(--gradientSecondary);

  font-family: var(--fontFamilyBase);

  color: #ffffff;
}

.secondary:hover {
  box-shadow: 0 6px 8px 4px var(--shadowCard);
}

.secondary:active {
  box-shadow: 0 2px 1.7px 0 var(--shadowCard);
}

.headerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 38px;
  border-radius: 4px;
}

.headerButtonText {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}

.fluid {
  width: 100%;
  text-align: center;
}

.link {
  composes: fonts vars;
  transition: var(--fastTransition);

  color: var(--text-meta-alt);
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.link:hover i {
  transition: var(--slowTransition);
  text-shadow: 2px 2px 4px rgba(142, 184, 255, 0.9);
  transform: scale(1.1);
}

.linkSecondary {
  composes: fonts;

  color: var(--grey6);
  font-size: 12px;
  font-weight: 500;
}

.ddlCaret {
  transform: rotate(0deg);

  transition: transform 0.3s ease-out;
}

.ddlCaretExpanded {
  transform: rotate(-180deg);
}

/* Migrated to styled-components, but preserved due to post-css inheritence
 * Both the ChatDetailsInfoSidebar and CreateChannelModal styles `compose` with
 * this class */

.buttonSecondary {
  composes: vars;
  padding: 8px;
  border-radius: 4px;
  box-shadow: var(--boxShadowButtonBasic);
  border: solid 1px var(--borderDarkAlt);
  background-color: var(--bgWhite);
  color: var(--text-primary);
  transition: var(--fastTransition);
}
.buttonSecondary:hover {
  box-shadow: 0 6px 5px 0 var(--shadowBasic);
}

.buttonSecondary .buttonHelpText {
  display: none;
  font-style: italic;
  color: var(--steel);
}
.buttonSecondary:hover .buttonHelpText {
  display: block;
}

.buttonSecondaryCompact {
  padding: 4px;
}
.buttonSecondaryCompact:hover {
  box-shadow: 0 6px 5px 0 var(--shadowBasic);
}

.buttonSecondaryCompact:hover .buttonHelpText {
  display: none;
}

.actionButton {
  composes: root;
  width: 46px;
  height: 46px;
  border-radius: 50%;

  background-color: var(--bgWhite);

  padding-left: unset;
  padding-right: unset;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: var(--paddingMin);
  margin-bottom: var(--paddingMin);
  margin-right: 12px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
}

.actionButton.small {
  margin-left: 6px;
  margin-right: 6px;
  width: 24px;
  height: 24px;

  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.actionButton.disabled > img {
  opacity: 0.5;
}

.actionButton:hover:not(.disabled) {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);
}

.actionButton:hover::after:not(.disabled) {
  position: absolute;
  bottom: -10px;
}

.actionButton:active:not(.disabled) {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.actionButton.disabled {
  box-shadow: none;
  cursor: not-allowed;
}

.disabled {
  filter: grayscale(50%);
  opacity: 75%;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.actionButton > img {
  object-fit: contain;
  object-position: center;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.actionButton.small > img {
  padding: 5px;
}

.actionButton > i {
  object-fit: contain;
  object-position: center;
  margin: 0 0 2px 0;
}

.actionButton.small > i {
  margin: 0 0 6px 1px;
}

.closeModalIcon {
  padding-right: 15px;
  padding-left: 0;
  line-height: 1em;
}
