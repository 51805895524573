/* @type */

:root {
  --boxShadowCard: 0 0.75rem 0.5rem -0.25rem var(--shadowCard);

  --boxShadowHeavy: 0 5px 15px 0 var(--shadowDark);

  --boxShadowButtonPrimary: 0 6px 5px 0 rgba(142, 184, 255, 0.5);

  --boxShadowButtonBasic: 0 1px 2px 0 var(--shadowBasic);

  --boxShadowHeader: 0 2px 16px 0 var(--shadowHeader);
}

.ssm-box-shadow-card {
  box-shadow: var(--boxShadowCard)
}
